import * as React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementStatsByIdentifier } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { elementDialogHide } from "../../core/store/elements/actions";
import {
  getElement,
  getElementDialog,
  getFixturesWithBlanks,
  getHistory,
  getSeasonHistory,
} from "../../core/store/elements/reducers";
import {
  IElement,
  IElementFixture,
  IElementFixtureBlank,
  IElementHistory,
  IElementSeasonHistory,
} from "../../core/store/elements/types";
import {
  getCurrentEvent,
  getNextEvent,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData, getWatched } from "../../core/store/player/reducers";
import {
  addToWatchlist,
  removeFromWatchlist,
} from "../../core/store/player/thunks";
import { IPlayer } from "../../core/store/player/types";
import { proposeElement } from "../../core/store/squad/thunks";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { formatRawAsLocal } from "../../core/utils/datetime";
import { integerToMoney } from "../../core/utils/money";
import { formatNumberToLocaleString } from "../../core/utils/number";
import {
  getElementStat,
  getRelevantStatNames,
} from "../../utils/element-stats";
import { getShortNameFromId } from "../../utils/events";
import { isElementFixtureHome } from "../../utils/fixtures";
import { getStatDescription } from "../../utils/stats";
import Alert from "../Alert";
import Badge from "../Badge";
import Button from "../Button";
import Dialog, { CloseButton, CloseButtonWrap, CloseIcon } from "../Dialog";
import ElementMatchGroup, {
  BlankMatchBox,
  ElementFixtureBox,
  ElementHistoryBox,
} from "../ElementMatchGroup";
import ElementPhoto from "../ElementPhoto";
import FixtureDifficulty from "../FixtureDifficulty";
import "../OverflowScrolling.css";
import { GradBorderBox, StyledFixtureStatsTable } from "../styles";
import SubHeading from "../SubHeading";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { VisuallyHidden } from "../Utils";
import EventHistoryTable from "./EventHistoryTable";
import FixtureOpponent from "./FixtureOpponent";
import StatItem from "./StatItem";
import {
  BadgeWrap,
  Club,
  DateCol,
  ElementDialogHeader,
  ElementHeading,
  ElementPhotoWrap,
  ElementTypeLabel,
  EventCol,
  FDRCol,
  FixturesTable,
  HistoryTableWrap,
  OppCol,
  RankTitle,
  StatList,
  StatsOuter,
  StatsWrap,
  Summary,
  TableWrap,
} from "./styles";

interface IOwnProps {
  element: IElement;
}

interface IPropsFromDispatch {
  addToWatched: (elementCode: number) => void;
  closeDialog: () => void;
  proposeElement: (elementId: number) => boolean;
  removeFromWatched: (elementCode: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementTypesById: IElementTypesById;
  fixtures: Array<IElementFixture | IElementFixtureBlank>;
  history: IElementHistory[];
  nextEvent: IEvent | null;
  player: IPlayer | null;
  seasonHistory: IElementSeasonHistory[];
  statsByName: Record<string, IElementStat>;
  teamsById: ITeamsById;
  watched: number[];
}

type Props = IOwnProps & IPropsFromDispatch & IPropsFromState;

class ElementDialog extends React.Component<Props> {
  public added = false;
  public componentWillUnmount() {
    this.props.closeDialog();
  }

  public addElement = (elementId: number) => {
    this.props.proposeElement(elementId);
    this.props.closeDialog();
  };

  public renderWatchlistButton() {
    const { addToWatched, element, player, removeFromWatched, watched } =
      this.props;
    if (!player || !player.entry) {
      return null;
    }
    const inWatched = watched.indexOf(element.code) > -1;
    if (inWatched) {
      return this.added ? (
        <p>View your watchlist on the transfers page</p>
      ) : (
        <Button
          onClick={() => {
            removeFromWatched(element.code);
          }}
          width={1}
        >
          Remove from Watchlist
        </Button>
      );
    }
    return (
      <Button
        onClick={() => {
          this.added = true;
          addToWatched(element.code);
        }}
        width={1}
      >
        Add to Watchlist
      </Button>
    );
  }

  public render() {
    const {
      closeDialog,
      currentEvent,
      element,
      elementTypesById,
      fixtures,
      history,
      nextEvent,
      seasonHistory,
      statsByName,
      teamsById,
    } = this.props;

    // Remove any fixtures from history. This can happen when a fixture in the current event is yet to start.
    const getHistoryWithoutFixtures = () => {
      const fixtureIds = new Set(
        fixtures.filter((f): f is IElementFixture => "id" in f).map((f) => f.id)
      );
      return history.filter((h) => !fixtureIds.has(h.fixture));
    };

    // Only get the last three in the history
    const lastThreeHistory = getHistoryWithoutFixtures().slice(-3);
    const nextThreeFixtures = fixtures.slice(0, 3);

    const getStatAbbr = (name: string) => {
      const abbr = getElementStat(name)?.shortName;
      return abbr
        ? abbr
        : statsByName[name].label
            .split(" ")
            .map((w) => w[0])
            .join("")
            .toUpperCase();
    };

    const getEventId = () => {
      if (currentEvent) {
        return currentEvent.id;
      }
      if (nextEvent) {
        return nextEvent.id;
      }
      return null;
    };

    const eventId = getEventId();

    const elementType = elementTypesById[element.element_type];

    const statNames = getRelevantStatNames(element.element_type);

    return (
      <Dialog closeDialog={closeDialog} isElementDialog={true}>
        <ElementDialogHeader>
          <CloseButtonWrap>
            <CloseButton onClick={closeDialog}>
              <VisuallyHidden>close</VisuallyHidden>
              <CloseIcon />
            </CloseButton>
          </CloseButtonWrap>
        </ElementDialogHeader>
        <Dialog.Body isPadded={false}>
          <Summary>
            {element.news && (
              <Alert type="news" copnr={element.chance_of_playing_next_round}>
                {element.news}
              </Alert>
            )}
            <Flex alignItems="center">
              <ElementPhotoWrap>
                <ElementPhoto
                  alt=""
                  elementId={element.id}
                  sizes="(min-width: 700px) 200px"
                />
              </ElementPhotoWrap>
              <div>
                <ElementTypeLabel>{elementType.singular_name}</ElementTypeLabel>
                <ElementHeading>
                  {element.first_name} {element.second_name}
                </ElementHeading>
                <Club>{teamsById[element.team].name}</Club>
              </div>
            </Flex>
            <StatsOuter>
              <StatsWrap>
                <StatList>
                  <StatItem
                    title="Price"
                    value={`£${integerToMoney(element.now_cost, 10)}m`}
                    rankType={element.now_cost_rank_type}
                    element={element}
                  />
                  <StatItem
                    title={
                      <Tooltip content={getStatDescription("form")}>
                        <span>
                          <TooltipLabel>Form</TooltipLabel>
                        </span>
                      </Tooltip>
                    }
                    value={element.form}
                    element={element}
                  />
                  <StatItem
                    title="Pts / Match"
                    value={element.points_per_game}
                    rankType={element.points_per_game_rank_type}
                    element={element}
                  />
                  <StatItem
                    title={eventId ? `${getShortNameFromId(eventId)} Pts` : ""}
                    value={element.event_points}
                    element={element}
                  />
                  <StatItem
                    title="Total Pts"
                    value={element.total_points}
                    element={element}
                  />
                  {element.element_type !== 5 && (
                    <>
                      <StatItem
                        title="Total Bonus"
                        value={element.bonus}
                        element={element}
                      />
                      <StatItem
                        title={
                          <Tooltip content="Influence, Creativity and Threat">
                            <span>
                              <TooltipLabel>ICT</TooltipLabel> Index
                            </span>
                          </Tooltip>
                        }
                        value={element.ict_index}
                        rankType={element.ict_index_rank_type}
                        element={element}
                      />
                    </>
                  )}
                  <StatItem
                    title={
                      <Tooltip content="Teams Selected By">
                        <span>
                          <TooltipLabel>TSB</TooltipLabel>%
                        </span>
                      </Tooltip>
                    }
                    value={`${element.selected_by_percent}%`}
                    rankType={element.selected_rank_type}
                    element={element}
                  />
                </StatList>
                <RankTitle id="rank-title">
                  Rankings for {elementType.plural_name}
                </RankTitle>
              </StatsWrap>
            </StatsOuter>
            <Flex mb={3}>
              <Box flex={1}>
                <ElementMatchGroup title="Form">
                  {lastThreeHistory.map((h) => (
                    <ElementHistoryBox key={h.fixture} history={h} />
                  ))}
                </ElementMatchGroup>
              </Box>
              <Box flex={1}>
                <ElementMatchGroup title="Fixtures">
                  {nextThreeFixtures.map((f) =>
                    f.code ? (
                      <ElementFixtureBox
                        key={f.code}
                        element={element}
                        fixture={f}
                      />
                    ) : (
                      <BlankMatchBox key={f.event} />
                    )
                  )}
                </ElementMatchGroup>
              </Box>
            </Flex>
          </Summary>
          <Tabs>
            <TabPanel label="History" link="history">
              <GradBorderBox>
                <TableWrap>
                  <Box m={2}>
                    <SubHeading>This Season</SubHeading>
                  </Box>
                  {history.length ? (
                    <EventHistoryTable elementId={element.id} />
                  ) : (
                    <p>Data will appear here once the season is underway.</p>
                  )}
                </TableWrap>
                {seasonHistory && seasonHistory.length > 0 && (
                  <TableWrap>
                    <Box m={2}>
                      <SubHeading>Previous Seasons</SubHeading>
                    </Box>
                    <HistoryTableWrap>
                      <StyledFixtureStatsTable>
                        <thead>
                          <tr>
                            <th scope="col">Season</th>
                            <th scope="col">Pts</th>
                            {statNames.map((n) => (
                              <th key={n} scope="col">
                                <Tooltip content={statsByName[n].label}>
                                  <TooltipLabel>{getStatAbbr(n)}</TooltipLabel>
                                </Tooltip>
                              </th>
                            ))}
                            <th scope="col">
                              <Tooltip content={"Price at start of season"}>
                                <TooltipLabel>£S</TooltipLabel>
                              </Tooltip>
                            </th>
                            <th scope="col">
                              <Tooltip content={"Price at end of season"}>
                                <TooltipLabel>£E</TooltipLabel>
                              </Tooltip>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {seasonHistory.map((h) => (
                            <tr key={h.season_name}>
                              <td>{h.season_name}</td>
                              <td>{h.total_points}</td>
                              {statNames.map((n) => (
                                <td key={n}>
                                  {formatNumberToLocaleString(
                                    h[statsByName[n].name]
                                  )}
                                </td>
                              ))}
                              <td>£{integerToMoney(h.start_cost, 10)}</td>
                              <td>£{integerToMoney(h.end_cost, 10)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </StyledFixtureStatsTable>
                    </HistoryTableWrap>
                  </TableWrap>
                )}
              </GradBorderBox>
            </TabPanel>
            <TabPanel label="Fixtures" link="fixtures">
              <GradBorderBox>
                <TableWrap>
                  <Box m={2}>
                    <SubHeading>This Season</SubHeading>
                  </Box>
                  <FixturesTable>
                    <thead>
                      <tr>
                        <DateCol scope="col">Date</DateCol>
                        <EventCol scope="col">GW</EventCol>
                        <OppCol scope="col">Opponent</OppCol>
                        <FDRCol scope="col">
                          <Tooltip content="Fixture Difficulty Rating">
                            <TooltipLabel>FDR</TooltipLabel>
                          </Tooltip>
                        </FDRCol>
                      </tr>
                    </thead>
                    <tbody>
                      {fixtures.map((f) =>
                        f.code ? (
                          <tr key={f.code}>
                            <td>
                              {f.kickoff_time ? (
                                <time dateTime={f.kickoff_time}>
                                  {formatRawAsLocal(f.kickoff_time)}
                                </time>
                              ) : (
                                <Tooltip content="Date To Be Confirmed">
                                  <span>
                                    <TooltipLabel>TBC</TooltipLabel>
                                  </span>
                                </Tooltip>
                              )}
                            </td>
                            <td>
                              {f.event ? getShortNameFromId(f.event, true) : ""}
                            </td>
                            <td>
                              <BadgeWrap>
                                <Badge
                                  alt=""
                                  team={
                                    teamsById[
                                      isElementFixtureHome(
                                        element.team,
                                        f.team_h
                                      )
                                        ? f.team_a
                                        : f.team_h
                                    ]
                                  }
                                  sizes="24px"
                                />
                              </BadgeWrap>
                              <FixtureOpponent
                                element={element}
                                fixture={f}
                                teamsById={teamsById}
                              />
                            </td>
                            <td>
                              <FixtureDifficulty
                                isLarge={true}
                                rating={f.difficulty}
                              />
                            </td>
                          </tr>
                        ) : (
                          <tr key={f.event!}>
                            <td>&nbsp;</td>
                            <td>
                              {f.event ? getShortNameFromId(f.event, true) : ""}
                            </td>
                            <td>None</td>
                            <td>&nbsp;</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </FixturesTable>
                </TableWrap>
              </GradBorderBox>
            </TabPanel>
          </Tabs>
          <Box mx={2} my={3}>
            {this.renderWatchlistButton()}
          </Box>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export { ElementDialog as ElementDialogTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => ({
  currentEvent: getCurrentEvent(state),
  elementTypesById: getElementTypesById(state),
  fixtures: getFixturesWithBlanks(state, ownProps.element.id) as Array<
    IElementFixture | IElementFixtureBlank
  >,
  history: getHistory(state, ownProps.element.id),
  nextEvent: getNextEvent(state),
  player: getPlayerData(state),
  seasonHistory: getSeasonHistory(state, ownProps.element.id).slice().reverse(),
  statsByName: getElementStatsByIdentifier(state),
  teamsById: getTeamsById(state),
  watched: getWatched(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  addToWatched: (elementCode) => dispatch(addToWatchlist(elementCode)),
  closeDialog: () => dispatch(elementDialogHide()),
  proposeElement: (elementId) => dispatch(proposeElement(elementId)),
  removeFromWatched: (elementCode) =>
    dispatch(removeFromWatchlist(elementCode)),
});

const ConnectedDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElementDialog);

/* tslint:disable:max-classes-per-file */
interface IContainerProps {
  element: IElement | undefined;
}

class ElementDialogContainer extends React.Component<IContainerProps> {
  public render() {
    const element = this.props.element;
    return element ? <ConnectedDialog element={element} /> : null;
  }
}

export default connect((state: RootState): IContainerProps => {
  const elementId = getElementDialog(state);
  return {
    element: getElement(state, elementId),
  };
})(ElementDialogContainer);
