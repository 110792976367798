import { rgba } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import {
  getActiveChip,
  getActiveChipName,
} from "../../core/store/chips/reducers";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { getNextEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import {
  getErrors,
  getFreeTransfersRemaining,
  getProposedElements,
  getToSpend,
  getTransferCosts,
} from "../../core/store/squad/reducers";
import { IProposedElements, ISquadErrors } from "../../core/store/squad/types";
import { formatRawAsISO, formatRawAsLocal } from "../../core/utils/datetime";
import { integerToMoney } from "../../core/utils/money";
import Alert from "../Alert";
import ChipList from "../Chips/ChipList";
import DeadlineBar from "../DeadlineBar";
import { ScoreboardPanel } from "../Scoreboard";

const ScoreboardPanelsWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    display: flex;
  }
`;

const ChipListWrap = styled.div`
  position: relative;
  margin: ${({ theme }) => `${theme.space[4]} 0 ${theme.space[2]} `};
  padding: 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex: 1 0 40%;
    margin: ${({ theme }) => theme.space[4]} 0;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const ScoreboardPanels = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex: 1 0 60%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
  }
`;

const ScoreboardWrap = styled.div`
  margin: 0 0.8rem 1.2rem;
  padding: 0 0.8rem;
  border-radius: ${({ theme }) => theme.radii[1]};
  background: ${rgba("white", 0.6)};
  box-shadow: 0 8px 12px 0 ${rgba("#6b6b6b", 0.32)};
  text-align: center;
`;

const ScoreboardPanelWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0 ${({ theme }) => theme.space[1]};
`;

interface IBasicProps {
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition;
  isBudgetExceeded: boolean;
  isNeedElements: boolean;
  nextEvent: IEvent | null;
  proposedElements: IProposedElements;
  toSpend: number;
}

const Basic: React.FC<IBasicProps> = ({
  currencyDivisor,
  elementTypesByPosition,
  isBudgetExceeded,
  isNeedElements,
  nextEvent,
  proposedElements,
  toSpend,
}) => (
  <ScoreboardWrap>
    {nextEvent && (
      <DeadlineBar
        deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
        deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
        heading={nextEvent.name}
        label={`${nextEvent.name} deadline`}
      />
    )}
    <ScoreboardPanelWrap>
      <ScoreboardPanel
        heading="Players Selected"
        value={`${Object.keys(proposedElements).length} / ${
          Object.keys(elementTypesByPosition).length
        }`}
        isError={isNeedElements}
        isSuccess={!isNeedElements}
      />
      <ScoreboardPanel
        heading="Budget"
        value={`${integerToMoney(toSpend, currencyDivisor)}`}
        isError={isBudgetExceeded}
        isSuccess={!isBudgetExceeded}
      />
    </ScoreboardPanelWrap>
  </ScoreboardWrap>
);

const UnlimitedTransfers: React.FC<IBasicProps> = (props) => {
  if (!props.nextEvent) {
    return null;
  }
  return (
    <>
      <Basic {...props} />
      <Box my={2}>
        <Alert isInline={true}>
          <p>
            You can make unlimited free transfers before the{" "}
            {props.nextEvent.name} deadline
          </p>
        </Alert>
      </Box>
    </>
  );
};

interface ICostProps {
  activeChipName: string;
  currencyDivisor: number;
  freeTransfersRemaining: number;
  isBudgetExceeded: boolean;
  nextEvent: IEvent | null;
  toSpend: number;
  transferCosts: number;
  isNeedElements: boolean;
  proposedElements: IProposedElements;
  elementTypesByPosition: IElementTypesBySquadPosition;
}

const Cost: React.FC<ICostProps> = ({
  activeChipName,
  currencyDivisor,
  freeTransfersRemaining,
  isBudgetExceeded,
  nextEvent,
  toSpend,
  transferCosts,
  proposedElements,
  isNeedElements,
  elementTypesByPosition,
}) => {
  const freeTranfersRemainingValue =
    activeChipName === "wildcard" || activeChipName === "freehit"
      ? "Unlimited"
      : `${freeTransfersRemaining}`;
  const budgetValue = `${integerToMoney(toSpend, currencyDivisor)}`;
  const transferCoststValue = `${transferCosts} pts`;
  const playersSelectedValue = `${Object.keys(proposedElements).length} / ${
    Object.keys(elementTypesByPosition).length
  }`;

  return (
    <ScoreboardWrap>
      {nextEvent && (
        <DeadlineBar
          deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
          deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
          heading={nextEvent.name}
          label={`${nextEvent.name} deadline`}
        />
      )}

      <ScoreboardPanelsWrap>
        <ChipListWrap>
          <ChipList chipsShown="transfer" />
        </ChipListWrap>
        <ScoreboardPanels>
          <ScoreboardPanel
            heading="Players Selected"
            value={playersSelectedValue}
            isError={isNeedElements}
            isSuccess={!isNeedElements}
          />
          <ScoreboardPanel
            heading="Budget"
            value={budgetValue}
            isError={isBudgetExceeded}
            isSuccess={!isBudgetExceeded}
          />
          <ScoreboardPanel
            heading="Free Transfers"
            value={freeTranfersRemainingValue}
          />
          <ScoreboardPanel
            heading="Cost"
            value={transferCoststValue}
            isError={transferCosts > 0}
          />
        </ScoreboardPanels>
      </ScoreboardPanelsWrap>
    </ScoreboardWrap>
  );
};
interface IOwnProps {
  scoreboard: string;
}

interface IPropsFromState {
  activeChipName: string;
  currencyDivisor: number;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  errors: ISquadErrors;
  freeTransfersRemaining: number;
  nextEvent: IEvent | null;
  proposedElements: IProposedElements;
  toSpend: number;
  transferCosts: number;
}

type Props = IOwnProps & IPropsFromState;

class Scoreboard extends React.Component<Props> {
  public render() {
    const {
      elementTypesByPosition,
      errors,
      proposedElements,
      scoreboard,
      ...rest
    } = this.props;
    if (!elementTypesByPosition) {
      return null;
    }
    const isNeedElements = Boolean(errors.needElements);
    const isBudgetExceeded = Boolean(errors.budgetExceeded);
    switch (scoreboard) {
      case "basic":
        return (
          <Basic
            {...rest}
            elementTypesByPosition={elementTypesByPosition}
            isNeedElements={isNeedElements}
            isBudgetExceeded={isBudgetExceeded}
            proposedElements={proposedElements}
          />
        );
      case "unlimitedTransfers":
        return (
          <UnlimitedTransfers
            {...rest}
            elementTypesByPosition={elementTypesByPosition}
            isNeedElements={isNeedElements}
            isBudgetExceeded={isBudgetExceeded}
            proposedElements={proposedElements}
          />
        );
      case "cost":
        return (
          <Cost
            {...rest}
            elementTypesByPosition={elementTypesByPosition}
            isNeedElements={isNeedElements}
            isBudgetExceeded={isBudgetExceeded}
            proposedElements={proposedElements}
          />
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = (state: RootState): IPropsFromState => {
  const activeChip = getActiveChip(state);
  return {
    activeChipName: getActiveChipName(state),
    currencyDivisor: 10,
    elementTypesByPosition: getElementTypesBySquadPosition(
      state,
      undefined,
      activeChip?.id
    ),
    errors: getErrors(state, undefined, activeChip?.id),
    freeTransfersRemaining: getFreeTransfersRemaining(state),
    nextEvent: getNextEvent(state),
    proposedElements: getProposedElements(state, undefined, activeChip?.id),
    toSpend: getToSpend(state, undefined, activeChip?.id),
    transferCosts: getTransferCosts(state),
  };
};

export default connect(mapStateToProps)(Scoreboard);
