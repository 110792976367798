import { BenchContentWrap } from "../../Pitch";
import { BenchHeader } from "../../styles";
import TeamPitchElement from "../TeamPitchElement";
import { StyledManagerUnit, TechnicalArea } from "./styles";
import { IManagerUnit } from "./types";

const ManagerUnit = ({
  pick,
  renderElementMenu,
  renderPickValue,
}: IManagerUnit) => {
  return (
    <StyledManagerUnit>
      <BenchContentWrap>
        <BenchHeader>Assistant Manager</BenchHeader>
        <TechnicalArea>
          <TeamPitchElement
            pick={pick}
            renderElementMenu={() => renderElementMenu(pick)}
            renderPickValue={renderPickValue}
          />
        </TechnicalArea>
      </BenchContentWrap>
    </StyledManagerUnit>
  );
};

export default ManagerUnit;
