import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import {
  getActiveChip,
  getPotentialChips,
} from "../../core/store/chips/reducers";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  showElementSummary,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import { IElement } from "../../core/store/elements/types";
import { getSettings } from "../../core/store/game/reducers";
import { IPick } from "../../core/store/my-team/types";
import { resetLastChange } from "../../core/store/squad/actions";
import {
  canAutocomplete,
  canReset,
  getErrors,
  getLastChange,
  getProposedElements,
  getSavedPicks,
  getSquadMode,
} from "../../core/store/squad/reducers";
import {
  autoComplete,
  removeElement,
  reset,
  restoreElement,
} from "../../core/store/squad/thunks";
import { getTeamsById } from "../../core/store/teams/reducers";
import { getElementRole } from "../../utils/element-type";
import { integerToMoneyWithCurrency } from "../../utils/money";
import Alert from "../Alert";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogManager from "../DialogManager";
import { PatternWrapMain } from "../GraphicPatterns";
import Pitch, { PitchRow, PitchUnit } from "../Pitch";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import SaveBar from "./SaveBar";
import Scoreboard from "./Scoreboard";
import SquadPitchUnit from "./SquadPitchUnit";
// import ShirtSoonAlert from "../ShirtSoonAlert";

//TODO: Move IListTableProps somewhere more abstract?
export interface IListTableProps {
  elementType: number;
  key: string;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (position: number) => void;
}

interface IOwnProps {
  listTable: (listTableProps: IListTableProps) => void;
  scoreboard: string;
  showSidebar: () => void;
  submitDialog: (handleHide: () => void) => React.ReactNode;
}

const CreateSquad: React.FC<IOwnProps> = ({
  listTable,
  scoreboard,
  showSidebar,
  submitDialog,
}) => {
  const [positionForMenu, setPositionForMenu] = React.useState<number>(0);

  const activeChip = useSelector(getActiveChip);
  const canUserAutocomplete = useSelector((state: RootState) =>
    canAutocomplete(state, undefined, activeChip?.id)
  );
  const canUserReset = useSelector(canReset);
  const currencyDivisor = 10;
  const elementsById = useSelector((state: RootState) =>
    getElementsById(state, undefined, activeChip?.id)
  );
  const errors = useSelector((state: RootState) =>
    getErrors(state, undefined, activeChip?.id)
  );
  const lastChange = useSelector(getLastChange);
  const mode = useSelector(getSquadMode);
  const proposedElements = useSelector((state: RootState) =>
    getProposedElements(state, undefined, activeChip?.id)
  );
  const savedPicks = useSelector(getSavedPicks);
  const settings = useSelector((state: RootState) =>
    getSettings(state, undefined, activeChip?.id)
  );
  const teamsById = useSelector(getTeamsById);
  const chips = useSelector(getPotentialChips);

  const dispatch = useDispatch();

  const autoPick = () => {
    if (!dispatch(autoComplete())) {
      // We should do something :-)
      window.console.log("Failed to autocomplete");
    }
  };

  const handleShowMenuForElement = (position: number) =>
    setPositionForMenu(position);

  const handleHideMenuForElement = () => setPositionForMenu(0);

  const handleRemoveElement = (position: number) => {
    handleHideMenuForElement();
    dispatch(removeElement(position));
  };

  const handleReset = () => dispatch(reset());

  const handleRestoreElement = (position: number) => {
    handleHideMenuForElement();
    dispatch(restoreElement(position));
  };

  const handleShowElementType = (elementType: number) => {
    handleHideMenuForElement();
    dispatch(updateElementTypeControl(elementType));
    showSidebar();
  };

  const showDialog = (element: IElement) => {
    dispatch(showElementSummary(element.id));
    handleHideMenuForElement();
  };

  const renderMenu = () => {
    let element: IElement | undefined;
    let mode: "remove" | "restore" | undefined;
    if (proposedElements[positionForMenu]) {
      element = proposedElements[positionForMenu];
      mode = "remove";
    } else if (savedPicks[positionForMenu]) {
      element = elementsById[savedPicks[positionForMenu].element];
      mode = "restore";
    }
    if (!mode || !element) {
      return null;
    }

    return (
      <Dialog closeDialog={handleHideMenuForElement}>
        <Dialog.Header closeDialog={handleHideMenuForElement}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        <Dialog.Body>
          <ul>
            {mode === "remove" ? (
              <DialogButtonItem>
                <Button
                  onClick={() => handleRemoveElement(positionForMenu)}
                  width={1}
                  variant="secondary"
                >
                  Remove {getElementRole(element.element_type)}
                </Button>
              </DialogButtonItem>
            ) : mode === "restore" ? (
              <>
                <DialogButtonItem>
                  <Button
                    onClick={() => handleRestoreElement(positionForMenu)}
                    width={1}
                    variant="secondary"
                  >
                    Restore {getElementRole(element.element_type)}
                  </Button>
                </DialogButtonItem>
                <DialogButtonItem>
                  <Button
                    onClick={() => handleShowElementType(element!.element_type)}
                    width={1}
                  >
                    Select Replacement
                  </Button>
                </DialogButtonItem>
              </>
            ) : (
              ""
            )}
            <DialogButtonItem>
              <Button
                onClick={() => showDialog(element as IElement)}
                width={1}
                variant="tertiary"
              >
                {getElementRole(element.element_type)} Information
              </Button>
            </DialogButtonItem>
          </ul>
        </Dialog.Body>
      </Dialog>
    );
  };

  const renderElementValueForPosition = (pos: number) => {
    const element: IElement = proposedElements[pos];
    const pick: IPick | undefined = savedPicks[pos];
    // Initial element selection (squad selection)
    if (!pick && element) {
      return integerToMoneyWithCurrency(element.now_cost, currencyDivisor);
    }
    // Removed element (transfers)
    if (pick && !element) {
      return integerToMoneyWithCurrency(pick.selling_price, currencyDivisor);
    }
    // Original element (transfers)
    if (pick && element && pick.element === element.id) {
      return integerToMoneyWithCurrency(pick.selling_price, currencyDivisor);
    }
    // Replaced element (transfers)
    if (pick && element && pick.element !== element.id) {
      return integerToMoneyWithCurrency(element.now_cost, currencyDivisor);
    }
    return null;
  };

  React.useEffect(() => {
    dispatch(resetLastChange());
  }, [dispatch]);

  let latestAction: React.ReactNode = null;
  if (lastChange.type === "addition") {
    latestAction = (
      <Alert isInline>
        <strong>{elementsById[lastChange.element].web_name}</strong> has been
        added to your squad
      </Alert>
    );
  } else if (lastChange.type === "removal") {
    latestAction = (
      <Alert isInline>
        <strong>{elementsById[lastChange.element].web_name}</strong> has been
        removed from your squad
      </Alert>
    );
  }

  // Button enablers
  const canEnter = !Boolean(Object.keys(errors).length);

  // Helper functions to generate props used by multiple component instances
  const sharedPitchElementProps = (pos: number) => ({
    pos,
    renderElementMenu: handleShowMenuForElement,
    elementValue: renderElementValueForPosition(pos),
    showSidebar,
  });

  const sharedSquadListTableProps = (
    pos: number[],
    elementType: number,
    key: string
  ) => ({
    elementType,
    key,
    positions: pos,
    renderElementDialog: showDialog,
    renderElementMenu: handleShowMenuForElement,
  });

  const submitButtonText = {
    selection: "Enter Squad",
    transfers: "Make Transfers",
  };

  const managerChip = chips.find((c) => c.name === "manager");

  const getPositionsByElementType = () => {
    const defaultPositionsByElementType: Record<string, number[]> = {
      "1": [1, 2],
      "2": [3, 4, 5, 6, 7],
      "3": [8, 9, 10, 11, 12],
      "4": [13, 14, 15],
    };

    return managerChip?.status_for_entry === "active"
      ? { ...defaultPositionsByElementType, "5": [16] }
      : defaultPositionsByElementType;
  };

  const positionsByElementType = getPositionsByElementType();

  return (
    <div>
      {settings && (
        <Box mx={2} mb={4}>
          <p>
            Select a maximum of {settings.squad_team_limit} players from a
            single team or 'Auto Pick' if you're short of time.
          </p>
        </Box>
      )}
      <PatternWrapMain showDeepGradient={true}>
        <Scoreboard scoreboard={scoreboard} />
        <div role="status" aria-live="polite">
          {latestAction && latestAction}
          {errors.overTeamLimit && (
            <Alert type="error" isInline>
              Too many players selected from{" "}
              <strong>
                {errors.overTeamLimit
                  .map((team) => teamsById[team].name)
                  .join(", ")}
              </strong>
            </Alert>
          )}
        </div>
        <Tabs>
          <TabPanel label="Pitch View" link="pitch">
            <Box pt={4}>
              <Pitch sponsor="default">
                {Object.keys(positionsByElementType).map((et) => (
                  <PitchRow key={et}>
                    {positionsByElementType[et].map((pos) => (
                      <PitchUnit key={pos}>
                        <SquadPitchUnit {...sharedPitchElementProps(pos)} />
                      </PitchUnit>
                    ))}
                  </PitchRow>
                ))}
              </Pitch>
            </Box>
          </TabPanel>
          <TabPanel label="List" link="list">
            <Box my={4}>
              {Object.keys(positionsByElementType).map((et) =>
                listTable(
                  sharedSquadListTableProps(
                    positionsByElementType[et],
                    parseInt(et, 10),
                    et
                  )
                )
              )}
            </Box>
          </TabPanel>
        </Tabs>
      </PatternWrapMain>
      {renderMenu()}
      <DialogManager
        render={(showDialog, handleShow, handleHide) => {
          return (
            <>
              <SaveBar>
                <Button
                  disabled={!canUserAutocomplete}
                  onClick={autoPick}
                  width={1}
                  variant="primary"
                >
                  Auto Pick
                </Button>
                <Button
                  disabled={!canUserReset}
                  onClick={handleReset}
                  type="reset"
                  width={1}
                  variant="secondary"
                >
                  Reset
                </Button>
                <Button
                  onClick={handleShow}
                  disabled={!canEnter}
                  width={1}
                  variant="secondary"
                >
                  {submitButtonText[mode]}
                </Button>
              </SaveBar>
              {showDialog && submitDialog(handleHide)}
            </>
          );
        }}
      />
    </div>
  );
};

export default CreateSquad;
