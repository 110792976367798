import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledScoreboardPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  flex: 1;
  align-content: center;
  margin: ${({ theme }) => `${theme.space[4]} ${theme.space[1]}`};
  padding: 0;
  text-align: center;
  cursor: default;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    justify-content: center;
    margin: ${({ theme }) => theme.space[4]} 0;
    padding: ${({ theme }) => `${theme.space[2]} 0`};

    :not(:last-child)::after {
      content: "";
      position: absolute;
      top: ${({ theme }) => theme.space[4]};
      right: 0;
      bottom: ${({ theme }) => theme.space[4]};
      width: 1px;
      background-color: #eaeaea;
    }
  }
`;

const Heading = styled.h3`
  margin: 0 0 0.4rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-decoration: none;
  &[title] {
    cursor: default;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    &::after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;

const Value = styled.div<IValue>`
  display: inline-block;
  width: 100%;
  padding: 3px 2px;
  border-radius: ${({ theme }) => theme.radii[0]};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[1]};

  ${(props) =>
    props.isError &&
    css`
      background-color: ${props.theme.colors.red};
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.isSuccess &&
    css`
      background-color: ${props.theme.colors.green};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: fit-content;
    padding: ${({ theme }) => `0.3rem ${theme.space[4]}`};
    border-radius: ${({ theme }) => theme.radii[2]};
    font-size: 2rem;
  }
`;

interface IScoreboardPanel {
  heading: string;
  value: string;
  onClick?: () => void;
}

interface IValue {
  isError?: boolean;
  isSuccess?: boolean;
}

type Props = IScoreboardPanel & IValue;

export const ScoreboardPanel: React.FC<Props> = ({
  heading,
  isError,
  isSuccess,
  value,
  onClick,
}) => (
  <StyledScoreboardPanel role="status" aria-live="polite" onClick={onClick}>
    <Heading>{heading}</Heading>
    <Value isError={isError} isSuccess={isSuccess}>
      {value}
    </Value>
  </StyledScoreboardPanel>
);
