import styled, { css } from "styled-components/macro";
import { BenchContentWrap } from "../Pitch";
import { BenchHeader } from "../styles";

interface IOwnProps {
  variant?: string | null;
}

const StyledBench = styled.div<IOwnProps>`
  display: flex;
  flex: 1;

  background: linear-gradient(
    to bottom,
    rgba(114, 207, 159) 50%,
    ${({ theme }) => theme.colors.offWhite} 50%
  );
  border-radius: ${({ theme }) => `${theme.radii[4]} ${theme.radii[4]}`} 0 0;
`;

const BenchInner = styled.div<IOwnProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 ${({ theme }) => theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    gap: ${({ theme }) => theme.space[1]};
    padding: 0 ${({ theme }) => theme.space[2]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    gap: ${({ theme }) => theme.space[3]};
    padding: 0 ${({ theme }) => theme.space[3]};
  }

  ${(props) =>
    props.variant === "bboost" &&
    css`
      box-shadow: 0 0 0 2px white,
        0 0 0 6px ${({ theme }) => theme.colors.lightBlue};
      border-radius: ${({ theme }) => `${theme.radii[4]} ${theme.radii[4]}`} 0 0;
    `}
`;

const Bench: React.FC<IOwnProps> = ({ children, variant = null }) => (
  <StyledBench variant={variant}>
    <BenchContentWrap>
      <BenchHeader>Substitutes</BenchHeader>
      <BenchInner variant={variant}>{children}</BenchInner>
    </BenchContentWrap>
  </StyledBench>
);

export default Bench;
