import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import {
  getElementEventOpponents,
  getFixturesForNextEventByTeam,
} from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import {
  getProposedElements,
  getSavedPicks,
} from "../../core/store/squad/reducers";
import { IProposedElements, ISavedPicks } from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { BorderedCell, ElementRow, ElementTable } from "../ElementTable";
import TabHeading from "../TabHeading";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import { getActiveChip } from "../../core/store/chips/reducers";

const SquadListStatus = styled.th`
  width: 7%;
`;

const SquadListElement = styled.th`
  width: 28%;
`;

const SquadListPrice = styled.th`
  width: 11%;
`;

const SquadListForm = styled.th`
  width: 8%;
`;

const SquadListPoints = styled.th`
  width: 9%;
`;

const SquadListFixture = styled.th`
  width: 15%;
`;

interface ISquadListRowProps {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  savedPicks: ISavedPicks;
  teamsById: ITeamsById;
}

const TransfersListRow: React.FC<ISquadListRowProps> = ({
  elementsById,
  elementTypesByPosition,
  fixturesForNextEventByTeam,
  proposedElements,
  pos,
  renderElementMenu,
  savedPicks,
  teamsById,
}) => {
  let element = proposedElements[pos];
  // TODO - We need a visual indicator of the row elementState, in the past we
  // have rendered the '0' shirt instead of the regular one
  let elementState: "original" | "removed" | "replaced" = "original";
  if (!element) {
    element = elementsById[savedPicks[pos].element];
    elementState = "removed";
  } else if (element.id !== savedPicks[pos].element) {
    elementState = "replaced";
  }

  const fixtures = fixturesForNextEventByTeam[element.team];
  const elementEventOpponents = useSelector((state: RootState) =>
    fixtures?.length && element
      ? getElementEventOpponents(state, element, fixtures)
      : []
  );

  if (!elementTypesByPosition) {
    return null;
  }

  return (
    <ElementRow>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <BorderedCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pos)}
          element={element}
          team={teamsById[element.team]}
        />
      </BorderedCell>
      <td>{integerToMoney(element.now_cost, 10)}</td>
      {elementState === "replaced" ? (
        <>
          <td>{integerToMoney(element.now_cost, 10)}</td>
          <td>{integerToMoney(element.now_cost, 10)}</td>
        </>
      ) : (
        <>
          <td>{integerToMoney(savedPicks[pos].selling_price, 10)}</td>
          <td>{integerToMoney(savedPicks[pos].purchase_price, 10)}</td>
        </>
      )}
      <td>{element.form}</td>
      <td>{element.total_points}</td>
      <td>
        {elementEventOpponents.length ? (
          elementEventOpponents.map((opp) => <div>{opp}</div>)
        ) : (
          <>&nbsp;</>
        )}
      </td>
    </ElementRow>
  );
};

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
  teamsById: ITeamsById;
}

interface IOwnProps {
  elementType: number;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IOwnProps;

const TransfersListTable: React.SFC<Props> = ({
  elementType,
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <>
      <Box mx={2}>
        <TabHeading
          title={elementTypesByPosition[positions[0]].thisType.plural_name}
          hasBorder={true}
        />
      </Box>
      <ScrollTable>
        <ElementTable>
          <thead>
            <tr>
              <SquadListStatus>&nbsp;</SquadListStatus>
              <SquadListElement>&nbsp;</SquadListElement>
              <SquadListPrice>
                <Tooltip content="Current Price">
                  <TooltipLabel>CP</TooltipLabel>
                </Tooltip>
              </SquadListPrice>
              <SquadListPrice>
                <Tooltip content="Selling Price">
                  <TooltipLabel>SP</TooltipLabel>
                </Tooltip>
              </SquadListPrice>
              <SquadListPrice>
                <Tooltip content="Purchase Price">
                  <TooltipLabel>PP</TooltipLabel>
                </Tooltip>
              </SquadListPrice>
              <SquadListForm>
                <Tooltip content="Form">
                  <TooltipLabel>F</TooltipLabel>
                </Tooltip>
              </SquadListForm>
              <SquadListPoints>
                <Tooltip content="Total Points">
                  <TooltipLabel>TP</TooltipLabel>
                </Tooltip>
              </SquadListPoints>
              <SquadListFixture>
                <Tooltip content="Fixture next Gameweek">
                  <TooltipLabel>Fix</TooltipLabel>
                </Tooltip>
              </SquadListFixture>
            </tr>
          </thead>
          <tbody>
            {positions.map((p) => (
              <TransfersListRow
                key={p}
                pos={p}
                elementTypesByPosition={elementTypesByPosition}
                {...rest}
              />
            ))}
          </tbody>
        </ElementTable>
      </ScrollTable>
    </>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const activeChip = getActiveChip(state);
  return {
    elementsById: getElementsById(state, undefined, activeChip?.id),
    elementTypesByPosition: getElementTypesBySquadPosition(
      state,
      undefined,
      activeChip?.id
    ),
    fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
    proposedElements: getProposedElements(state, undefined, activeChip?.id),
    savedPicks: getSavedPicks(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(TransfersListTable);
