import styled from "styled-components/macro";
import { BenchHeader } from "../../styles";

// Handle the width of the flex child
export const StyledManagerUnit = styled.div`
  flex: 0 0 20%;
`;

// Handle the 'technical area' separately because flex-basis is better than width for sizing flex children
export const TechnicalArea = styled.div`
  position: relative;
  width: min-content;
  margin: auto;
  padding-top: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-right: ${({ theme }) => theme.space[3]};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 12px;
    border: 3px solid white;
    border-bottom: none;
    border-radius: ${({ theme }) => theme.radii[2]}
      ${({ theme }) => theme.radii[2]} 0 0;
    margin: 0 5px;
  }
`;

export const ManagerHeader = styled(BenchHeader)`
  margin-top: -${({ theme }) => theme.space[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
    justify-content: center;
  }
`;
