import React from "react";
import { useSelector } from "react-redux";
import { getElementsById } from "../core/store/elements/reducers";
import { getSettings } from "../core/store/game/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import Shirt from "./Shirt";

interface IProps {
  alt: string;
  elementId: number;
  hasShadow?: boolean;
  sizes: string;
}

export const getElementShirtPath = (
  elementType: number,
  teamCode: number,
  shirtMode: string
) => {
  const suffix = elementType === 1 ? `_${elementType}` : "";
  return `${process.env.PUBLIC_URL}/dist/img/shirts/${shirtMode}/shirt_${teamCode}${suffix}`;
};

const ElementShirt: React.FC<IProps> = ({
  alt,
  elementId,
  hasShadow,
  sizes,
}) => {
  const element = useSelector(getElementsById)[elementId];
  const settings = useSelector(getSettings);
  const team = useSelector(getTeamsById)[element.team];

  let shirtMode = "standard";
  if (settings && settings.ui_use_special_shirts) {
    if (settings.ui_special_shirt_exclusions.indexOf(element.code) === -1) {
      shirtMode = "special";
    }
  }

  return (
    <Shirt
      alt={alt}
      hasShadow={hasShadow}
      path={getElementShirtPath(
        element.element_type,
        element.has_temporary_code ? 0 : team.code,
        shirtMode
      )}
      sizes={sizes}
    />
  );
};

export default ElementShirt;
