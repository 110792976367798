import { Link, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import { hideVisually, rgba, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import {
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
} from "../../core/store/entries/reducers";
import {
  fetchEntryEventPicks,
  fetchEntrySummary,
} from "../../core/store/entries/thunks";
import {
  IEntry,
  IEntryEvent,
  IPickLight,
} from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventByTeam } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { ReactComponent as BaseDreamTeam } from "../../img/icons/dreamteam.svg";
import { ReactComponent as BaseReload } from "../../img/icons/reload.svg";
import { getChipName } from "../../utils/chips";
import BoldLink from "../BoldLink";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import { PatternWrapMain } from "../GraphicPatterns";
import HelmetHead from "../HelmetHead";
import { Main, Secondary, Wrapper } from "../Layout";
import LeaderboardAd from "../LeaderboardAd";
import {
  EventPager,
  NextLink,
  PagerHeading,
  PagerItem,
  PagerItemNext,
  PreviousLink,
} from "../Pager";
import Pitch, { BenchRow, InnerBenchRow } from "../Pitch";
import { NotFound } from "../Routes";
import Table from "../Table";
import Title from "../Title";
import { textGradient } from "../Utils";
import { ControlArrowRight } from "../icons/Arrows";
import DreamTeam from "../icons/DreamTeam";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Bench from "./Bench";
import BenchUnit from "./BenchUnit";
import ElementExplainDialog from "./ElementExplainDialog";
import EntryEventTable from "./EntryEventTable";
import PitchFormation from "./PitchFormation";
import valueForPlayedElement from "./valueForPlayedElement";
import ManagerUnit from "./ManagerUnit";

const PointsScoreboard = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space[4]};

  ::before {
    content: "";
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => rgba(theme.colors.lightGrey, 0)} 0%,
      ${({ theme }) => theme.colors.lightGrey} 50%,
      ${({ theme }) => rgba(theme.colors.lightGrey, 0)} 100%
    );
  }
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  text-align: center;
  padding: ${({ theme }) => theme.space[3]} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 90%;
    margin: 0 auto ${({ theme }) => theme.space[4]};
  }
`;

const ScoreboardPrimary = styled.div`
  flex: 0 0 38%;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: calc(22% - ${({ theme }) => theme.space[1]});
  }
`;

interface IPrimaryPanelProps {
  chipPlayed?: boolean;
}

const PrimaryPanel = styled.div<IPrimaryPanelProps>`
  position: relative;
  padding: ${({ theme }) => theme.space[2]} 0;

  ${(props) =>
    props.chipPlayed &&
    css`
      padding-top: 4rem;
    `}
`;

const PrimaryHeading = styled.h4`
  margin-bottom: ${({ theme }) => theme.space[1]};
  color: white;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.2rem;
  }
`;

const PrimaryValue = styled.div`
  ${textGradient}
  font-size: 6.2rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 7.2rem;
  }
`;

const ReloadButton = styled.button`
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 1;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: #05fa87;
`;

const ReloadButtonText = styled.span`
  ${hideVisually()}
`;

const ScoreboardSecondary = styled.div`
  flex: 0 0 calc(31% - ${({ theme }) => theme.space[2]});
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 36%;
  }
`;

const SecondaryPanel = styled.div`
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-around;
  }
`;

const SecondaryItem = styled.div`
  overflow: auto;
  text-align: center;

  :not(:last-child) {
    border-bottom: 1px solid #d8d8d8;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 1 calc(50% - ${({ theme }) => theme.space[1]});

    :not(:last-child) {
      border-bottom: 0;
    }
  }
`;

const ItemLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
`;

const SecondaryHeading = styled.h4`
  margin: ${({ theme }) => theme.space[1]} 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.2rem;
  }
`;

const SecondaryLinkHeading = styled(SecondaryHeading)`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const SecondaryValue = styled.div`
  margin: ${({ theme }) => theme.space[1]} 0;
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 2.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    font-size: 3rem;
  }
`;

const TransfersValue = styled(SecondaryValue)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransfersCost = styled.span`
  margin-left: 0.5rem;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    font-size: 2rem;
  }
`;

const DreamTeamLinkIcon = styled(BaseDreamTeam)`
  ${size(16)}
  margin-right: ${({ theme }) => theme.space[1]};
  color: white;
  fill: #005baa;
  flex-shrink: 0;
`;

const DreamTeamWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  text-align: center;
`;

const ChipStatus = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;
`;

type OwnProps = RouteComponentProps<{ entryId: string; eventId: string }>;

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  entry: IEntry | null;
  entryData: IEntryEvent | null;
  event: IEvent | null;
  fixturesForEventByTeam: IFixturesForEventByTeam;
  formation: string;
  mine: boolean;
  now: IEvent | null;
  nxt: IEvent | null;
  points: number;
  settings: ISettings;
}

interface IPropsFromDispatch {
  fetchEntryEventPicks: (entryId: number, eventId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchEventLive: (entryId: number) => void;
  fetchFixturesForEvent: (eventId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickLight | null;
}

class EntryEvent extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.entryData!.picks.filter(
      (p) => p.element === element
    );
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsDataById &&
    this.props.elementsDataById[pick.element] &&
    this.props.elementsDataById[pick.element].stats.in_dreamteam ? (
      <Link to={`/team-of-the-week/${this.props.event!.id}`}>
        <DreamTeam />
      </Link>
    ) : null;

  public fetchDataAndFixtures = () => {
    this.fetchData();
    this.props.fetchFixturesForEvent(Number(this.props.eventId));
  };

  public fetchData = () => {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryEventPicks(
      Number(this.props.entryId),
      Number(this.props.eventId)
    );
    this.props.fetchEventLive(Number(this.props.eventId));
  };

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.entryId !== this.props.entryId
    ) {
      this.fetchData();
    }
  }
  public render() {
    const {
      elementsById,
      elementsDataById,
      entry,
      entryData,
      event,
      fixturesForEventByTeam,
      formation,
      mine,
      now,
      nxt,
      points,
      settings,
    } = this.props;
    // Handle unknown and unstarted events
    if (!now || !event || event.id > now.id) {
      return <NotFound />;
    }
    if (!entry || !entryData) {
      return null;
    }

    const chipName = entryData.active_chip
      ? getChipName(entryData.active_chip)
      : "";

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = valueForPlayedElement({
      elementsById,
      eventId: event.id,
      fixturesForEventByTeam,
    });

    const managerPick = entryData.picks.find(
      (pick) => elementsById[pick.element].element_type === 5
    );

    // Handle events before the entry started
    return (
      <>
        <LeaderboardAd slot="Leaderboard_Points" id="ism-points-ad" />
        <Wrapper>
          <HelmetHead
            title={`View Latest ${entry.player_first_name} ${entry.player_last_name} Gameweek Points | Fantasy Premier League`}
            description={`To view the latest Fantasy Premier League Gameweek points of ${entry.player_first_name} ${entry.player_last_name}, visit the official website of the Premier League.`}
          />
          <Main>
            <Box mx={2}>
              <Title>Points - {entry.name}</Title>
            </Box>
            <PatternWrapMain>
              <PagerHeading role="status" aria-live="polite">
                {event.name}
              </PagerHeading>
              {(event.id > entry.started_event || event.id < now.id) && (
                <EventPager>
                  {event.id > entry.started_event && (
                    <PagerItem>
                      <PreviousLink
                        to={`/entry/${entry.id}/event/${event.id - 1}`}
                      />
                    </PagerItem>
                  )}
                  {event.id < now.id && (
                    <PagerItemNext>
                      <NextLink
                        to={`/entry/${entry.id}/event/${event.id + 1}`}
                      />
                    </PagerItemNext>
                  )}
                  {mine && nxt && event.id === now.id && (
                    <PagerItemNext>
                      <NextLink to={`/my-team`} />
                    </PagerItemNext>
                  )}
                </EventPager>
              )}
              <PointsScoreboard>
                <ScoreboardSecondary>
                  <SecondaryPanel>
                    <SecondaryItem>
                      <SecondaryHeading>Average Points</SecondaryHeading>
                      <SecondaryValue>
                        {event.average_entry_score || "-"}
                      </SecondaryValue>
                    </SecondaryItem>
                    <SecondaryItem>
                      {event.highest_scoring_entry ? (
                        <ItemLink
                          to={`/entry/${event.highest_scoring_entry}/event/${event.id}`}
                        >
                          <SecondaryLinkHeading>
                            Highest Points <ControlArrowRight />
                          </SecondaryLinkHeading>
                          <SecondaryValue>
                            {event.highest_score || "-"}
                          </SecondaryValue>
                        </ItemLink>
                      ) : (
                        <>
                          <SecondaryHeading>Highest Points</SecondaryHeading>
                          <SecondaryValue>-</SecondaryValue>
                        </>
                      )}
                    </SecondaryItem>
                  </SecondaryPanel>
                </ScoreboardSecondary>
                <ScoreboardPrimary>
                  <PrimaryPanel chipPlayed={Boolean(chipName)}>
                    {chipName && (
                      <ChipStatus>{`${chipName} played`}</ChipStatus>
                    )}
                    <div>
                      <PrimaryHeading>
                        {event.finished ? "Final Points" : "Latest Points"}
                      </PrimaryHeading>
                      <PrimaryValue>{points}</PrimaryValue>
                    </div>
                    {!event.finished && (
                      <ReloadButton onClick={this.fetchDataAndFixtures}>
                        <ReloadButtonText>Reload Points</ReloadButtonText>
                        <Reload />
                      </ReloadButton>
                    )}
                  </PrimaryPanel>
                </ScoreboardPrimary>
                <ScoreboardSecondary>
                  <SecondaryPanel>
                    <SecondaryItem>
                      <SecondaryHeading>GW Rank</SecondaryHeading>
                      <SecondaryValue>
                        {entryData.entry_history.rank
                          ? entryData.entry_history.rank.toLocaleString()
                          : "-"}
                      </SecondaryValue>
                    </SecondaryItem>
                    <SecondaryItem>
                      {event.highest_scoring_entry ? (
                        <ItemLink to={`/entry/${entry.id}/transfers`}>
                          <SecondaryLinkHeading>
                            Transfers <ControlArrowRight />
                          </SecondaryLinkHeading>
                          <TransfersValue>
                            <span>
                              {entryData.entry_history.event_transfers}
                            </span>
                            {entryData.entry_history.event_transfers_cost ? (
                              <TransfersCost>{`(-${entryData.entry_history.event_transfers_cost} pts)`}</TransfersCost>
                            ) : (
                              ""
                            )}
                          </TransfersValue>
                        </ItemLink>
                      ) : (
                        <>
                          <SecondaryHeading>Highest Points</SecondaryHeading>
                          <SecondaryValue>-</SecondaryValue>
                        </>
                      )}
                    </SecondaryItem>
                  </SecondaryPanel>
                </ScoreboardSecondary>
              </PointsScoreboard>
              <DreamTeamWrap>
                <BoldLink to={`/team-of-the-week/${event.id}`}>
                  <DreamTeamLinkIcon />
                  <span>Team of the Week</span>
                </BoldLink>
              </DreamTeamWrap>
              <Tabs>
                <TabPanel label="Pitch View" link="pitch">
                  <Box pt={4}>
                    <Pitch sponsor="default">
                      <PitchFormation
                        chipName={entryData.active_chip}
                        eventId={event.id}
                        formation={formation}
                        picks={entryData.picks}
                        renderDreamTeam={this.renderDreamTeam}
                        renderElementMenu={this.handleShowMenuForPickElement}
                        renderPickValue={renderPickValue}
                      />
                      <BenchRow>
                        <InnerBenchRow>
                          {entryData.active_chip === "manager" &&
                            managerPick && (
                              <ManagerUnit
                                pick={managerPick}
                                renderElementMenu={() =>
                                  this.handleShowMenuForPickElement(
                                    managerPick.element
                                  )
                                }
                                renderPickValue={renderPickValue}
                              />
                            )}
                          <Bench variant={entryData.active_chip}>
                            {range(
                              settings.squad_squadplay,
                              settings.squad_squadsize
                            ).map((e, i) => (
                              <BenchUnit
                                key={e}
                                chipName={entryData.active_chip}
                                eventId={event.id}
                                index={i}
                                pick={entryData.picks[e]}
                                renderDreamTeam={this.renderDreamTeam}
                                renderElementMenu={() =>
                                  this.handleShowMenuForPickElement(
                                    entryData.picks[e].element
                                  )
                                }
                                renderPickValue={renderPickValue}
                              />
                            ))}
                          </Bench>
                        </InnerBenchRow>
                      </BenchRow>
                    </Pitch>
                  </Box>
                </TabPanel>
                <TabPanel label="List View" link="list">
                  <Box my={4} bg="white">
                    <EntryEventTable
                      title="Starters"
                      picks={entryData.picks.slice(0, settings.squad_squadplay)}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      chipName={entryData.active_chip}
                      dataById={elementsDataById}
                    />
                    <EntryEventTable
                      title="Substitutes"
                      picks={entryData.picks.slice(settings.squad_squadplay)}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      chipName={entryData.active_chip}
                      dataById={elementsDataById}
                    />
                  </Box>
                </TabPanel>
              </Tabs>
            </PatternWrapMain>

            <Box mb={4}></Box>

            {/* TEAM */}

            {entryData.automatic_subs.length > 0 && (
              <Box mb={4}>
                <Box mx={2}>
                  <Title>Automatic Substitutions</Title>
                </Box>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Player out</th>
                      <th scope="col">Player in</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entryData.automatic_subs.map((s) => (
                      <tr key={s.element_out}>
                        <td>{elementsById[s.element_out].web_name}</td>
                        <td>{elementsById[s.element_in].web_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            )}
            {this.state.pickForMenu && (
              <ElementExplainDialog
                elementId={this.state.pickForMenu.element}
                eventId={event.id}
                closeDialog={this.handleHideMenuForPick}
              />
            )}
            <Fixtures eventId={event.id} />
          </Main>
          <Secondary>
            <Entry entryId={entry.id} />
          </Secondary>
        </Wrapper>
      </>
    );
  }
}
export { EntryEvent as EntryEventTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const player = getPlayerData(state) as IPlayer;
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    entry: getEntry(state, entryId),
    event: getEventsById(state)[eventId],
    now: getCurrentEvent(state),
    nxt: getNextEvent(state),
    entryData: getEntryEventPicks(state, entryId, eventId),
    fixturesForEventByTeam: getFixturesForEventByTeam(state, eventId),
    formation: getEntryEventFormation(state, entryId, eventId),
    points: getEntryEventPoints(state, entryId, eventId),
    settings: getSettings(state) as ISettings,
    mine: Boolean(player && player.entry && player.entry === entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryEventPicks: (entryId, eventId) =>
    dispatch(fetchEntryEventPicks(entryId, eventId)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryEvent);
