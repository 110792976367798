import * as React from "react";
import { getShortNameFromId } from "../../../utils/events";
import ChipBadge from "../ChipBadge";
import {
  Card,
  CardButton,
  ChevronRightIcon,
  ChipCardTitle,
  ChipLabel,
  ChipLabelWrap,
} from "./styles";
import { IChipCardProps } from "./types";

const ChipCard: React.FC<IChipCardProps> = ({ chip, onClick, title }) => {
  const chipStatus = chip.status_for_entry !== "active" ? "Inactive" : "";
  const chipLabelText = () => {
    switch (chip.status_for_entry) {
      case "unavailable":
        return "Unavailable";
      case "proposed":
        return "Pending";
      case "active":
        if (chip.is_pending) {
          return "Pending";
        }
        return "Active";
      case "cancelled":
      case "available":
        return "Play";
      case "played":
        const eventsPlayed = chip.played_by_entry.map((id) =>
          getShortNameFromId(id, true)
        );
        return (
          <>
            {"Played "}
            {eventsPlayed.length > 1
              ? eventsPlayed.join(", ")
              : `GW${eventsPlayed[0]}`}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <Card>
      <CardButton onClick={onClick} chipStatus={chip.status_for_entry}>
        <ChipBadge
          path={`${process.env.PUBLIC_URL}/img/chips/${chip.name}${chipStatus}`}
          sizes="30px"
        />
        <ChipCardTitle>{title}</ChipCardTitle>
        <ChipLabelWrap>
          <ChipLabel
            $fullWidth={chip.status_for_entry !== "played"}
            chipStatus={
              chip.name === "managerplaceholder"
                ? "info"
                : chip.status_for_entry
            }
          >
            {chip.name === "managerplaceholder" ? "Info" : chipLabelText()}
          </ChipLabel>
          {chip.status_for_entry === "played" && <ChevronRightIcon />}
        </ChipLabelWrap>
      </CardButton>
    </Card>
  );
};

export default ChipCard;
