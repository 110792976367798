import { hiDPI, rgba, size } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as BaseHeadlineLogo } from "../../img/headline-logo.svg";
import leftArrow from "../../img/icons/chevron-left-small.svg";
import rightArrow from "../../img/icons/chevron-right-small.svg";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import pattern1_437 from "../../img/pattern/pattern-1-437.png";
import pattern1_618 from "../../img/pattern/pattern-1-618.png";
import pattern1_874 from "../../img/pattern/pattern-1-874.png";
import Table from "../Table";

export const BadgeWrap = styled.span`
  margin-right: ${(props) => props.theme.space[1]};
`;

export const ElementDialogHeader = styled.div`
  position: relative;
  z-index: 3;
`;

export const HeadlineLogo = styled(BaseHeadlineLogo)`
  ${size(18, 79)}
  display: inline-block;
  margin: ${(props) => props.theme.space[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${size(24, 105)}
    display: block;
    margin: 1.8rem auto;
  }
`;

export const Summary = styled.div`
  padding: ${(props) => `4rem ${props.theme.space[2]} 0`};
  border-radius: ${({ theme }) => theme.radii[1]};

  background-image: linear-gradient(
      to bottom,
      ${rgba("white", 0)} 160px,
      white 260px
    ),
    url(${pattern1_437}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lightBlue},
      ${({ theme }) => theme.colors.darkBlue}
    );
  background-repeat: no-repeat;
  background-position: 0 0, right -25px top -185px, 0 0;
  background-size: auto, 273px 387px, auto;

  ${hiDPI(2)} {
    background-image: linear-gradient(
        to bottom,
        ${rgba("white", 0)} 160px,
        white 260px
      ),
      url(${pattern1_618}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-size: auto, 273px 387px, auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-image: linear-gradient(
        to bottom,
        ${rgba("white", 0)} 180px,
        white 320px
      ),
      url(${pattern1_874}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lightBlue},
        ${({ theme }) => theme.colors.darkBlue}
      );
    background-position: 0 0, right -84px top -493px, 0 0;
    background-size: auto, 653px 925px, auto;

    ${hiDPI(2)} {
      background-image: linear-gradient(
          to bottom,
          ${rgba("white", 0)} 180px,
          white 320px
        ),
        url(${pattern1_1236}),
        linear-gradient(
          to right,
          ${({ theme }) => theme.colors.lightBlue},
          ${({ theme }) => theme.colors.darkBlue}
        );
      background-size: auto, 653px 925px, auto;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 8%;
    padding-left: 8%;
  }
`;

export const ElementTypeLabel = styled.span`
  display: inline-block;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  border-radius: ${({ theme }) => `0 0 ${theme.radii[1]} ${theme.radii[1]}`};
  background-color: ${({ theme }) => theme.colors.primary};
  color: #05f0ff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  line-height: 1;
`;

export const ElementHeading = styled.h2`
  margin: ${(props) => props.theme.space[1]} 0;
  font-size: 1.8rem;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-top: ${(props) => props.theme.space[2]};
    margin-bottom: ${(props) => props.theme.space[2]};
    font-size: 2.8rem;
  }
`;

export const Club = styled.div`
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const Img = styled.img`
  display: block;
  max-width: 100%;
`;

export const ElementPhotoWrap = styled.div`
  flex: 0 0 130px;
  margin: 0 1.4rem 0 0.3rem;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex: 0 0 200px;
    margin: 0 4rem;
  }
`;

export const Glass = css`
  background-color: ${rgba("white", 0.6)};
  box-shadow: 0 4px 12px 0 ${rgba("black", 0.08)};
`;

export const StatsOuter = styled.div`
  ${Glass}
  position: relative;
  padding-top: ${({ theme }) => theme.space[3]};
  margin: 0 0 ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[1]};

  ::before {
    left: 0;
    background-image: linear-gradient(
        to left,
        ${rgba("black", 0)} 0%,
        ${rgba("black", 0.08)} 100%
      ),
      url(${leftArrow});
  }

  ::after {
    right: 0;
    background-image: linear-gradient(
        to right,
        ${rgba("black", 0)} 0%,
        ${rgba("black", 0.08)} 100%
      ),
      url(${rightArrow});
  }

  ::before,
  ::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    background-repeat: no-repeat, no-repeat;
    background-position: 0 0, 50% 50%;

    @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
      background-image: none;
    }
  }
`;

export const StatsWrap = styled.div`
  overflow: auto;
`;

export const StatDivider = css`
  :not(:first-child) {
    position: relative;

    ::before {
      content: "";
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 0;
      width: 1px;
      background-color: ${rgba("black", 0.1)};
    }
  }
`;

export const StatList = styled.ul`
  display: flex;
`;

export const RankTitle = styled.div`
  margin: 0.8rem 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  text-align: center;
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
  /* We're adding this in OverflowScrolling.css
	 * because the property gets removed from the
	 * styled component on test */
  /* -webkit-overflow-scrolling: touch; */
`;

export const TableWrap = styled.div`
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-right: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[2]};
  }
`;

export const HistoryTableWrap = styled.div`
  overflow-x: scroll;
  position: relative;
`;

export const HistoryTotalsHeading = styled.th`
  font-family: ${(props) => props.theme.fonts.regular};
`;

export const HistoryTable = styled(Table)`
  white-space: nowrap;
  background-color: #fff;

  th {
    font-weight: normal;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;

    :nth-of-type(2) {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  tbody,
  tfoot {
    th {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #fff;
    }

    ${HistoryTotalsHeading} {
      font-size: ${(props) => props.theme.fontSizes[2]};
    }
  }

  tfoot {
    th {
      font-weight: normal;
      text-align: right;
    }
  }
`;

export const HistoryTotalsRow = styled.tr`
  tfoot {
    th,
    td {
      background-color: ${(props) => props.theme.colors.offWhite};
    }
  }
`;

export const HistoryPer90Row = styled.tr`
  th,
  td {
    font-family: ${(props) => props.theme.fonts.italic};
    font-size: ${(props) => props.theme.fontSizes[0]};
  }
`;

export const FixturesTable = styled(Table)`
  table-layout: fixed;
  white-space: nowrap;

  td {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      padding-top: ${(props) => props.theme.space[1]};
      padding-bottom: ${(props) => props.theme.space[1]};
    }
  }
`;

export const DateCol = styled.th`
  width: 33%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 24%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 16%;
  }
`;

export const EventCol = styled.th`
  width: 13%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 8%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 6%;
  }
`;

export const OppCol = styled.th`
  width: 37%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 58%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 67%;
  }
`;

export const FDRCol = styled.th`
  width: 17%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 14%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 15%;
  }
`;
