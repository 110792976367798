import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/store";
import { getActiveChip } from "../../core/store/chips/reducers";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { updateElementTypeControl } from "../../core/store/elements/thunks";
import {
  getProposedElements,
  getSavedPicks,
} from "../../core/store/squad/reducers";
import { removeElement, restoreElement } from "../../core/store/squad/thunks";
import PitchPlaceholder from "./PitchPlaceholder";
import SquadPitchElement from "./SquadPitchElement";

interface ISquadPitchUnit {
  pos: number;
  elementValue: React.ReactNode;
  renderElementMenu: (pos: number) => void;
  showSidebar: () => void;
}

const SquadPitchUnit = ({
  elementValue,
  pos,
  renderElementMenu,
  showSidebar,
}: ISquadPitchUnit) => {
  const chip = useSelector(getActiveChip);
  const elementTypesByPosition = useSelector((state: RootState) =>
    getElementTypesBySquadPosition(state, undefined, chip?.id)
  );
  const proposedElements = useSelector((state: RootState) =>
    getProposedElements(state, undefined, chip?.id)
  );
  const savedPicks = useSelector(getSavedPicks);

  const dispatch = useDispatch();

  const showElementType = (elementTypeId: number) => {
    dispatch(updateElementTypeControl(elementTypeId));
    showSidebar();
  };

  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <>
      {proposedElements[pos] ? (
        <SquadPitchElement
          actionMe={() => dispatch(removeElement(pos))}
          elementId={proposedElements[pos].id}
          elementValue={elementValue}
          renderElementMenu={() => renderElementMenu(pos)}
        />
      ) : savedPicks[pos] ? (
        <SquadPitchElement
          actionMe={() => dispatch(restoreElement(pos))}
          elementId={savedPicks[pos].element}
          elementValue={elementValue}
          renderElementMenu={() => renderElementMenu(pos)}
          isRemoved={true}
        />
      ) : (
        <PitchPlaceholder
          elementType={elementTypesByPosition[pos].thisType}
          onClick={() =>
            showElementType(elementTypesByPosition[pos].thisType.id)
          }
        />
      )}
    </>
  );
};

export default SquadPitchUnit;
