import { formatInTimeZone } from "date-fns-tz";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../../core/store";
import { IGroupFixture } from "../../core/store/fixtures/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { formatRawAsISO } from "../../core/utils/datetime";
import tvIcon from "../../img/tv.png";
import tvIcon2x from "../../img/tv@2x.png";
import Badge from "../Badge";
import Dialog from "../Dialog";
import DialogManager from "../DialogManager";
import { FixtureBroadcastersContext } from "../FixtureBroadcasters";
import SubHeading from "../SubHeading";
import Table from "../Table";
import { NewTabCopy } from "../Utils";
import {
  Broadcaster,
  BroadcasterBox,
  BroadcasterItem,
  BroadcasterList,
  ButtonText,
  ButtonWrap,
  ExternalIcon,
  FixtureBadge,
  FixtureKOTime,
  FixtureScore,
  FixtureScoreTeam,
  StyledFixture,
  StyledLogo,
  Team,
  TeamH,
  TeamName,
  Teams,
  TVButton,
  TVLink,
  TVLogo,
} from "./styles";
import { IMultipleBroadcastersDialog } from "./types";

const MultipleBroadcastersDialog: React.FC<IMultipleBroadcastersDialog> = ({
  broadcasters,
  handleHide,
}) => (
  <Dialog closeDialog={handleHide}>
    <Dialog.Header closeDialog={handleHide}>TV Info</Dialog.Header>
    <Dialog.Body isPadded={true}>
      <SubHeading>Broadcasters</SubHeading>
      <Box mx={-2}>
        <Table>
          <thead>
            <tr>
              <th scope="col">Broadcaster</th>
              <th scope="col"></th>
              <th scope="col">More info</th>
            </tr>
          </thead>
          <tbody>
            {broadcasters.map((broadcaster) => (
              <tr key={broadcaster.abbreviation}>
                <td>
                  {broadcaster.name.substring(
                    broadcaster.name.indexOf(" - ") + 3
                  )}
                </td>
                <td>
                  <Logo
                    abbreviation={broadcaster.abbreviation}
                    name={broadcaster.name}
                  />
                </td>
                <td>
                  <TVLink
                    href={broadcaster.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonText>Visit site</ButtonText>
                    <NewTabCopy />
                    <ExternalIcon />
                  </TVLink>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </Dialog.Body>
  </Dialog>
);

interface ILogoProps {
  abbreviation: string;
  name: string;
}

const Logo: React.FC<ILogoProps> = ({ abbreviation, name }) => {
  const path =
    "https://resources.premierleague.com/premierleague/broadcasters/global";
  const abbr = abbreviation.toLowerCase();
  return (
    <StyledLogo
      srcSet={`${path}/${abbr}.png, ${path}/${abbr}@x2.png 2x`}
      src={`${path}/${abbr}.png`}
      alt={name}
    />
  );
};

interface IPropsFromState {
  teamsById: ITeamsById;
}

interface IOwnProps {
  fixture: IGroupFixture;
}

type Props = IPropsFromState & IOwnProps;

const Fixture: React.FC<Props> = ({ fixture, teamsById }) => {
  const fb = React.useContext(FixtureBroadcastersContext);
  const broadcasterInfo =
    fixture.event && fb[fixture.event] && fb[fixture.event][fixture.id]
      ? fb[fixture.event][fixture.id]
      : null;
  return (
    <StyledFixture isStarted={fixture.started && fixture.team_h_score !== null}>
      <Teams>
        <TeamH>
          <TeamName>{teamsById[fixture.team_h].name}</TeamName>
          <FixtureBadge>
            <Badge
              alt=""
              team={teamsById[fixture.team_h]}
              sizes="(min-width: 1024px) 40px, 32px"
            />
          </FixtureBadge>
        </TeamH>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore $finished={fixture.finished_provisional}>
            <FixtureScoreTeam>{fixture.team_h_score}</FixtureScoreTeam>
            <FixtureScoreTeam>{fixture.team_a_score}</FixtureScoreTeam>
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm"
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <Team>
          <FixtureBadge>
            <Badge
              alt=""
              team={teamsById[fixture.team_a]}
              sizes="(min-width: 1024px) 40px, 30px"
            />
          </FixtureBadge>
          <TeamName>{teamsById[fixture.team_a].name}</TeamName>
        </Team>
      </Teams>
      {broadcasterInfo && broadcasterInfo.length > 0 && (
        <Broadcaster>
          {broadcasterInfo.length > 2 ? (
            <DialogManager
              render={(showDialog, handleShow, handleHide) => (
                <>
                  <ButtonWrap>
                    <TVButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShow();
                      }}
                    >
                      <TVLogo
                        src={tvIcon}
                        srcSet={`${tvIcon}, ${tvIcon2x} 2x`}
                        alt=""
                      />
                      <ButtonText>Multiple Broadcasters</ButtonText>
                    </TVButton>
                  </ButtonWrap>
                  {showDialog && (
                    <MultipleBroadcastersDialog
                      broadcasters={broadcasterInfo}
                      handleHide={handleHide}
                    />
                  )}
                </>
              )}
            />
          ) : broadcasterInfo.length === 2 ? (
            <BroadcasterList>
              {broadcasterInfo.map((b) => (
                <BroadcasterItem key={b.abbreviation}>
                  <a
                    href={b.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Logo abbreviation={b.abbreviation} name={b.name} />
                    <NewTabCopy />
                    <ExternalIcon />
                  </a>
                </BroadcasterItem>
              ))}
            </BroadcasterList>
          ) : (
            <BroadcasterBox>
              <a
                href={broadcasterInfo[0].url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <Logo
                  abbreviation={broadcasterInfo[0].abbreviation}
                  name={broadcasterInfo[0].name}
                />
                <NewTabCopy />
                <ExternalIcon />
              </a>
            </BroadcasterBox>
          )}
        </Broadcaster>
      )}
    </StyledFixture>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(Fixture);
