import React from "react";
import { ITeam } from "../../core/store/teams/types";
import {
  getPulseTeamIdFromRefs,
  getTeamsByPulseId,
  IPulseNewsArticle,
} from "../../utils/pulse";
import Badge from "../Badge";
import {
  ExternalIcon,
  IconWrap,
  RelatedHeadline,
  RelatedLink,
} from "../news/styles";
import { NewTabCopy } from "../Utils";

interface IExternalHeadlineContent {
  team: ITeam | null;
  title: string;
}

const ExternalHeadlineContent: React.FC<IExternalHeadlineContent> = ({
  team,
  title,
}) => (
  <>
    <IconWrap>
      {team && <Badge alt={`${team.name} website`} team={team} sizes="28px" />}
    </IconWrap>
    <RelatedHeadline>
      {title}
      <ExternalIcon title="External link" />
    </RelatedHeadline>
  </>
);

interface IExternalHeadline {
  article: IPulseNewsArticle;
  teams: ITeam[];
}

const ExternalHeadline: React.FC<IExternalHeadline> = ({ article, teams }) => {
  const teamsByPulseId = getTeamsByPulseId(teams);
  const { hotlinkUrl, references, title } = article;
  const pulseId = references.length ? getPulseTeamIdFromRefs(references) : null;
  const team = pulseId ? teamsByPulseId[pulseId] : null;

  return (
    <>
      {hotlinkUrl ? (
        <RelatedLink
          href={hotlinkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalHeadlineContent team={team} title={title} />
          <NewTabCopy />
        </RelatedLink>
      ) : (
        <RelatedLink as="span">
          <ExternalHeadlineContent team={team} title={title} />
          <NewTabCopy />
        </RelatedLink>
      )}
    </>
  );
};

export default ExternalHeadline;
