import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import { addChips } from "../bootstrap/actions";
import { fetchMyTeam, saveMyTeam } from "../my-team/actions";
import * as actions from "./actions";
import { IChip, IPotentialChip, IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(addChips):
        const newState: { [key: string]: IChip } = {};
        action.payload.forEach((chip) => {
          newState[chip.id] = chip;
        });
        return newState;
      default:
        return state;
    }
  },
  potential: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(fetchMyTeam.success):
      case getType(saveMyTeam.success):
      case getType(actions.saveChipTeam.success):
      case getType(actions.fetchChipState.success):
        return action.payload.data.chips;

      case getType(actions.proposeAvailableChip): {
        if (someActiveOrProposed(state)) {
          return state;
        }
        return state.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload &&
            c.status_for_entry.match(/^(available|unavailable)$/)
              ? "proposed"
              : c.status_for_entry,
        }));
      }

      case getType(actions.cancelProposedChip): {
        return state.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload && c.status_for_entry === "proposed"
              ? "available"
              : c.status_for_entry,
        }));
      }

      case getType(actions.cancelActiveChip):
        return state.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload &&
            c.status_for_entry === "active" &&
            c.chip_type === "team"
              ? "cancelled"
              : c.status_for_entry,
        }));

      case getType(actions.restoreCancelledChip): {
        if (someActiveOrProposed(state)) {
          return state;
        }
        return state.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload && c.status_for_entry === "cancelled"
              ? "active"
              : c.status_for_entry,
        }));
      }

      default:
        return state;
    }
  },
});

const someActiveOrProposed = (chips: IPotentialChip[]) =>
  chips.some(
    (c) => c.status_for_entry === "active" || c.status_for_entry === "proposed"
  );

// State Selectors / Helpers
export const getChipsById = (state: RootState) => state.chips.byId;

export const getChipByChipId = createSelector(
  [getChipsById, (_: RootState, chipId: number) => chipId],
  (chips, chipId) => chips[chipId]
);

export const getPotentialChips = (state: RootState) => state.chips.potential;

export const getPlayedChips = createSelector(getPotentialChips, (chips) =>
  chips.filter((c) => c.status_for_entry === "played")
);

export const getActiveChip = createSelector(getPotentialChips, (chips) => {
  const matches = chips.filter((c) => c.status_for_entry === "active");
  return matches.length ? matches[0] : null;
});

export const getActiveChipName = createSelector(getActiveChip, (chip) =>
  chip ? chip.name : ""
);

export const getProposedChip = createSelector(getPotentialChips, (chips) => {
  const matches = chips.filter((c) => c.status_for_entry === "proposed");
  return matches.length ? matches[0] : null;
});

export const getProposedChipName = createSelector(getProposedChip, (chip) =>
  chip ? chip.name : ""
);

// This is most relevent for transfer chips. On team chips we have to consider
// unavailable as well as they can become 'available' if an active chip is
// cancelled
export const getAvailableChipNames = createSelector(
  getPotentialChips,
  (chips) =>
    chips.filter((c) => c.status_for_entry === "available").map((c) => c.name)
);

export const teamChipsHaveChanged = createSelector(getPotentialChips, (chips) =>
  chips.some(
    (c) =>
      c.chip_type === "team" &&
      Boolean(c.status_for_entry.match(/^(cancelled|proposed)$/))
  )
);

export const getActiveOrProposedTeamChipName = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<string | null>(
      (memo, c) =>
        c.chip_type === "team" &&
        c.status_for_entry.match(/^(active|proposed)$/)
          ? c.name
          : memo,
      null
    )
);

export const getActiveOrProposedTransferChipName = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<string | null>(
      (memo, c) =>
        c.chip_type === "transfer" &&
        c.status_for_entry.match(/^(active|proposed)$/)
          ? c.name
          : memo,
      null
    )
);

export const getActiveOrProposedChip = createSelector(
  getPotentialChips,
  (chips) =>
    chips.reduce<IPotentialChip | null>(
      (memo, c) => (c.status_for_entry.match(/^(active|proposed)$/) ? c : memo),
      null
    )
);

export const getActiveOrProposedAllChipName = createSelector(
  getActiveOrProposedChip,
  (chip) => (chip ? chip.name : null)
);
