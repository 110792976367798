import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElement } from "../../core/store/elements/types";
import { IEvent } from "../../core/store/events/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { getShortNameFromId, isBlankWeek } from "../../utils/events";
import ElementShirt from "../ElementShirt";
import { StyledPitchElement } from "../PitchCard";
import PlaceholderShirt from "../PlaceholderShirt";
import { UnstyledButton } from "../Utils";

// TODO Fix this - it won't work
const StyledTopElement = styled(StyledPitchElement)`
  width: 9rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 11rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    width: 12rem;
  }
`;

export const TopElementName = styled.div`
  padding: 0.3rem 0.2rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

export const TopElementValue = styled.div`
  padding: 0.2rem;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.lightBlue}
  );
  border-radius: 0 0 3px 3px;
  font-size: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

export const TopElementValueInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

const Gameweek = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

const Points = styled.span`
  margin-left: 1rem;
`;

interface IPropsFromState {
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  element: IElement | null;
  event: IEvent;
  points: number | null;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

const EventTopElement: React.FC<Props> = ({
  element,
  showElementDialog,
  teamsById,
  event,
  points,
}) => {
  const shirtProps = {
    hasShadow: true,
    sizes: "(min-width: 1024px) 55px, (min-width: 610px) 44px, 37px",
  };
  return (
    <StyledTopElement>
      {element && !isBlankWeek(event.id) ? (
        <UnstyledButton
          type="button"
          onClick={() => showElementDialog(element.id)}
        >
          <ElementShirt
            {...shirtProps}
            alt={teamsById[element.team].name}
            elementId={element.id}
          />
          <div>
            <TopElementName>{element.web_name}</TopElementName>
            <TopElementValue>
              <TopElementValueInner>
                <Gameweek>{getShortNameFromId(event.id)}</Gameweek>
                <Points>{`${points} pts`}</Points>
              </TopElementValueInner>
            </TopElementValue>
          </div>
        </UnstyledButton>
      ) : (
        <div>
          <PlaceholderShirt {...shirtProps} />
          <div>
            <TopElementName>-</TopElementName>
            <TopElementValue>
              <span>{getShortNameFromId(event.id)}</span>
            </TopElementValue>
          </div>
        </div>
      )}
    </StyledTopElement>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  teamsById: getTeamsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventTopElement);
