import * as React from "react";
import styled from "styled-components/macro";
import { ITeam } from "../core/store/teams/types";

const StyledBadge = styled.img`
  display: inline-block;
  vertical-align: middle;
`;

const badgePath = "https://resources.premierleague.com/premierleague/badges/70";
const prefix = "t";

interface IProps {
  alt: string;
  sizes: string;
  team: ITeam;
}

const Badge: React.FC<IProps> = ({ alt, sizes, team }) => {
  const code = team.code || 0;

  if (!code) {
    return null;
  }

  const path = `${badgePath}/${prefix}${code}`;

  const imgProps = {
    alt,
    role: alt === "" ? "presentation" : undefined,
    sizes,
    src: `${path}.png`,
    srcSet: `${path}.png 70w, ${path}@x2.png 140w`,
  };

  return <StyledBadge {...imgProps} />;
};

export default Badge;
