import { useSelector } from "react-redux";
import { getElementsById } from "../../core/store/elements/reducers";
import { StyledElementPhoto } from "./styles";
import { IElementPhotoProps } from "./types";

const ElementPhoto = ({ alt, elementId, sizes }: IElementPhotoProps) => {
  const elementsById = useSelector(getElementsById);
  const element = elementsById[elementId];

  const elementPhotoDir = `//resources.premierleague.com/premierleague/photos/players`;
  const missingPhotoFilename = "Photo-Missing";
  const elementPhotoFilename = element.has_temporary_code
    ? missingPhotoFilename
    : `${element.opta_code}`;

  const getPhotoPath = (size: "110x140" | "250x250", filename: string) =>
    `${elementPhotoDir}/${size}/${filename}.png`;

  const getImgSrcSet = (filename: string) => {
    return `${getPhotoPath("110x140", filename)} 220w,
      ${getPhotoPath("250x250", filename)} 500w,
      `;
  };

  return (
    <StyledElementPhoto
      src={getPhotoPath("110x140", elementPhotoFilename)}
      srcSet={getImgSrcSet(elementPhotoFilename)}
      sizes={sizes}
      alt={alt}
      role={alt === "" ? "presentation" : undefined}
      onError={(e) => {
        e.currentTarget.srcset = getImgSrcSet(missingPhotoFilename);
      }}
    />
  );
};

export default ElementPhoto;
