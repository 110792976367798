import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

interface IStyledPitch {
  pitch: string;
}

export interface IElementName {
  copnr: number | null;
}

const StyledPitch = styled.div<IStyledPitch>`
  min-height: 330px;
  border-radius: 6px;
  background: url(${(props) => props.pitch}) no-repeat center top;
  background-size: 574px 460px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 524px;
    padding-top: 5px;
    background-size: 860px 690px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 690px;
    background-size: 1122px 900px;
  }
`;

export const ElementRow = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    max-width: 420px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: 620px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: none;
  }
`;

export const PitchRow = styled(ElementRow)`
  padding-bottom: 0.3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-bottom: 2.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-bottom: 4.5rem;

    &:last-child {
      padding-bottom: 1.5rem;
    }
  }
`;

export const PitchUnit = styled.div`
  flex: 0 1 25%;
  min-height: 7.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    min-height: 9.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 11.6rem;
  }
`;

// BenchRow is independent of ElementRow because we want the gradient full width
export const BenchRow = styled.div`
  padding: ${({ theme }) => theme.space[2]} 0 ${({ theme }) => theme.space[2]};
  background: linear-gradient(
    to bottom,
    transparent 50%,
    ${({ theme }) => theme.colors.offWhite} 50%
  );
`;

// InnerBenchRow is constrained by max-width irrespective of manager or not
export const InnerBenchRow = styled.div`
  display: flex;
  margin: auto;
  max-width: fit-content;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    gap: ${({ theme }) => theme.space[1]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    gap: ${({ theme }) => theme.space[3]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    gap: ${({ theme }) => theme.space[3]};
  }
`;

// BenchContentWrap can be used for both Manager and Bench units
export const BenchContentWrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: ${({ theme }) => theme.space[2]};
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    gap: ${({ theme }) => theme.space[3]};
  }
`;

interface IProps {
  sponsor: string;
}

const Pitch: React.FC<IProps> = ({ children, sponsor }) => {
  let [pitchSrc, setPitchSrc] = useState("");

  useEffect(() => {
    import(`../img/pitches/pitch-${sponsor}.svg`).then((src) => {
      setPitchSrc(src.default);
    });
  }, [sponsor]);

  return (
    <StyledPitch pitch={pitchSrc} data-testid="pitch">
      {children}
    </StyledPitch>
  );
};

export default Pitch;
