import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getActiveChip } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { getElement } from "../../core/store/elements/reducers";
import { IPickLight } from "../../core/store/entries/types";
import { actionSubstitution } from "../../core/store/my-team/thunks";
import Tooltip, { TooltipLabel } from "../Tooltip";
import TeamPitchElement from "./TeamPitchElement";

const BenchUnitHeading = styled.h4`
  margin: ${({ theme }) => theme.space[1]} 0;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  text-align: center;
  letter-spacing: -0.1px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: ${({ theme }) => theme.space[2]};
    margin-bottom: ${({ theme }) => theme.space[2]};
    font-size: ${(props) => props.theme.fontSizes[3]};
  }
`;
interface IProps {
  index: number;
  pick: IPickLight;
  chipName?: string | null;
  eventId?: number;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: (pick: IPickLight) => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

const BenchUnit = ({
  index,
  pick,
  renderDreamTeam,
  renderElementMenu,
  renderPickValue,
}: IProps) => {
  const activeChip = useSelector(getActiveChip);
  const element = useSelector((state: RootState) =>
    getElement(state, pick.element)
  );
  const elementTypesById = useSelector((state: RootState) =>
    getElementTypesById(state, undefined, activeChip?.id)
  );
  const reduxDispatch = useDispatch<ThunkDispatch>();

  if (!element) {
    return null;
  }
  const elementType = elementTypesById[element.element_type];
  return (
    <div>
      <BenchUnitHeading>
        {`${index ? `${index}. ` : ""}`}
        <Tooltip content={elementType.singular_name}>
          <TooltipLabel>{elementType.singular_name_short}</TooltipLabel>
        </Tooltip>
      </BenchUnitHeading>
      <TeamPitchElement
        actionMe={() => reduxDispatch(actionSubstitution(pick.element))}
        pick={pick}
        renderDreamTeam={renderDreamTeam}
        renderElementMenu={() => renderElementMenu(pick)}
        renderPickValue={renderPickValue}
      />
    </div>
  );
};

export default BenchUnit;
