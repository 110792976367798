import { integerToMoney } from "../../../core/utils/money";
import { getSuffixFromId } from "../../../utils/fixtures";
import Badge from "../../Badge";
import { BorderedCell } from "../../ElementTable";
import Result from "../../Result";
import { BadgeWrap } from "../styles";
import { IHistoryRow } from "./types";

const HistoryRow: React.FC<IHistoryRow> = ({
  history,
  teamsById,
  statsByName,
  statNames,
}) => {
  const getResult = (teamAScore: number, teamBScore: number) => {
    if (teamAScore > teamBScore) {
      return "W";
    }
    if (teamAScore < teamBScore) {
      return "L";
    }
    return "D";
  };

  const getElementResult = () =>
    history.was_home
      ? getResult(history.team_h_score, history.team_a_score)
      : getResult(history.team_a_score, history.team_h_score);

  const oppTeam = teamsById[history.opponent_team];
  return (
    <tr>
      <td>{history.round}</td>
      <th scope="row">
        <BadgeWrap>
          <Badge alt={oppTeam.name} team={oppTeam} sizes="24px" />
        </BadgeWrap>
        {oppTeam.short_name}{" "}
        {getSuffixFromId(history.fixture, history.was_home)}{" "}
        {history.team_h_score !== null &&
          `${history.team_h_score} - ${history.team_a_score}`}
      </th>
      <td>
        <Result resultChar={getElementResult()} />
      </td>
      <BorderedCell>{history.total_points}</BorderedCell>
      {statNames.map((n) => (
        <td key={n}>{history[statsByName[n].name]}</td>
      ))}
      <td>{history.transfers_balance}</td>
      <td>{history.selected}</td>
      <td>£{integerToMoney(history.value, 10)}</td>
    </tr>
  );
};

export default HistoryRow;
