import { rgba } from "polished";
import React from "react";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import {
  IElement,
  IElementFixture,
  IElementHistory,
} from "../core/store/elements/types";
import { getTeamsById } from "../core/store/teams/reducers";
import { ReactComponent as BadgeBlankBase } from "../img/icons/badge-blank.svg";
import { isElementFixtureHome } from "../utils/fixtures";
import Badge from "./Badge";
import FixtureDifficulty from "./FixtureDifficulty";
import { MatchValueBase } from "./Utils";

const MatchListTitle = styled.h3`
  display: flex;
  align-items: center;
  margin: ${(props) => `0 ${props.theme.space[1]} ${props.theme.space[2]}`};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  color: ${(props) => props.theme.colors.primary};
  text-align: center;

  ::before,
  ::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: ${(props) => rgba(props.theme.colors.primary, 0.2)};
  }

  :not(:empty)::before {
    margin-right: 0.25rem;
  }

  :not(:empty)::after {
    margin-left: 0.25rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-right: 0.8rem;
    margin-left: 0.8rem;

    :not(:empty)::before {
      margin-right: 0.35rem;
    }

    :not(:empty)::after {
      margin-left: 0.35rem;
    }
  }
`;

const MatchList = styled.ul`
  display: flex;
`;

export const MatchBox = styled.li`
  flex: 0 0 calc(100% / 3 - ${(props) => props.theme.space[1]});
  text-align: center;
  padding: ${(props) => props.theme.space[1]};
  background-color: ${rgba("white", 0.5)};
  box-shadow: 0 1px 8px 0 ${rgba("black", 0.08)};

  :not(:first-child) {
    margin-left: ${(props) => props.theme.space[1]};
  }

  :first-child {
    border-radius: ${({ theme }) => `${theme.radii[1]} 0 0 ${theme.radii[1]}`};
  }

  :last-child {
    border-radius: ${({ theme }) => `0 ${theme.radii[1]} ${theme.radii[1]} 0`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => `0.6rem ${props.theme.space[2]}`};
  }
`;

const MatchBoxEvent = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
  }
`;
const MatchBoxBadge = styled.div`
  margin-bottom: ${(props) => props.theme.space[1]};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 24%;
    margin-bottom: 0;
  }
`;
const MatchBoxValue = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 40%;
  }
`;

const MatchEvent = styled.h4`
  margin-bottom: 0.2rem;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1rem;
  color: ${(props) => props.theme.colors.darkGrey};
  text-align: center;
`;

const BadgeBlank = styled(BadgeBlankBase)`
  display: inline-block;
  vertical-align: middle;
`;

const HistoryPts = styled.div<IMatchPts>`
  ${MatchValueBase}
  ${(props) =>
    props.isBlank
      ? css`
          background-color: transparent;
          color: ${props.theme.colors.darkGrey};
        `
      : css`
          background-color: #f8f8f8;
          color: ${props.theme.colors.primary};
        `}
`;

interface IMatchPts {
  isBlank?: boolean;
}

export const BlankMatchBox: React.FC = () => (
  <MatchBox>
    <Box mb="3px">
      <BadgeBlank />
    </Box>
    <HistoryPts isBlank={true}>-</HistoryPts>
  </MatchBox>
);

interface IElementHistoryBox {
  history: IElementHistory;
}

export const ElementHistoryBox: React.FC<IElementHistoryBox> = ({
  history,
}) => {
  const teamsById = useSelector(getTeamsById);
  const opponentTeam = teamsById[history.opponent_team];
  return (
    <MatchBox>
      <MatchBoxEvent>
        <MatchEvent>{`GW${history.round}`}</MatchEvent>
      </MatchBoxEvent>
      <MatchBoxBadge>
        <Badge alt={opponentTeam.name} sizes="24px" team={opponentTeam} />
      </MatchBoxBadge>
      <MatchBoxValue>
        <HistoryPts>{`${history.total_points}pts`}</HistoryPts>
      </MatchBoxValue>
    </MatchBox>
  );
};

interface IElementFixtureBox {
  element: IElement;
  fixture: IElementFixture;
}

export const ElementFixtureBox: React.FC<IElementFixtureBox> = ({
  element,
  fixture,
}) => {
  const teamsById = useSelector(getTeamsById);
  const opponentTeam = isElementFixtureHome(element.team, fixture.team_h)
    ? teamsById[fixture.team_a]
    : teamsById[fixture.team_h];
  return (
    <MatchBox>
      <MatchBoxEvent>
        {fixture.event && <MatchEvent>{`GW${fixture.event}`}</MatchEvent>}
      </MatchBoxEvent>
      <MatchBoxBadge>
        <Badge alt={opponentTeam.name} sizes="24px" team={opponentTeam} />
      </MatchBoxBadge>
      <MatchBoxValue>
        <FixtureDifficulty rating={fixture.difficulty} />
      </MatchBoxValue>
    </MatchBox>
  );
};

interface IElementFixtureGroup {
  title: string;
}

const ElementMatchGroup: React.FC<IElementFixtureGroup> = ({
  children,
  title,
}) => (
  <div>
    <MatchListTitle>{title}</MatchListTitle>
    <MatchList>{children}</MatchList>
  </div>
);

export default ElementMatchGroup;
