import sortBy from "lodash/sortBy";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import { getElementTypesById } from "../element-types/reducers";
import {
  getElementsById,
  getFormation,
  getTotalsByType,
} from "../elements/reducers";
import { getSettings } from "../game/reducers";
import { ISettings } from "../game/types";
import * as actions from "./actions";
import { IPickProposed, IState, SubStatus } from "./types";
import { getActiveOrProposedChip } from "../chips/reducers";

// State Reducer
export default combineReducers<IState, RootAction>({
  picksLastUpdated: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchMyTeam.success):
      case getType(actions.saveMyTeam.success):
        return action.payload.data.picks_last_updated;
      default:
        return state;
    }
  },
  picksProposed: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchMyTeam.success):
      case getType(actions.saveMyTeam.success):
        return sortBy(action.payload.data.picks, "position").map((e) => ({
          ...e,
          elementType: action.payload.elementsById[e.element].element_type,
          subStatus: "" as SubStatus,
        }));

      case getType(actions.substitutionStart):
        return state.map((p) => {
          const newPick = { ...p };
          if (p === action.payload.pick) {
            newPick.subStatus = "instigator";
          } else if (action.payload.possibleReplacements.indexOf(p) > -1) {
            newPick.subStatus = "target";
          } else {
            newPick.subStatus = "invalid";
          }
          return newPick;
        });

      case getType(actions.substitutionStop):
        return state.map((p) => ({ ...p, subStatus: "" as SubStatus }));

      case getType(actions.substitutionProcess): {
        // Find the pick who instigated the substitution
        const instigator = state.reduce<IPickProposed | null>(
          (memo, p) => (p.subStatus === "instigator" ? p : memo),
          null
        );
        if (!instigator) {
          return state;
        }

        // Swap positions / captain data and reset subStatus
        const attrs: Array<keyof IPickProposed> = [
          "position",
          "is_captain",
          "is_vice_captain",
        ];
        const target = action.payload.pick;
        const newPicks = state.map((p) => {
          const newPick = { ...p };
          // See https://github.com/microsoft/TypeScript/issues/31663 for
          // explanation for use of 'as any'
          if (p.element === instigator.element) {
            attrs.forEach((a) => ((newPick[a] as any) = target[a]));
          } else if (p.element === target.element) {
            attrs.forEach((a) => ((newPick[a] as any) = instigator[a]));
          }
          newPick.subStatus = "";
          return newPick;
        });

        // Order by position, factoring in elementType for starters
        const subStart = action.payload.subsStart;
        newPicks.sort((a, b) => {
          if (a.position < subStart && b.position < subStart) {
            return (
              a.elementType * 99 +
              a.position -
              (b.elementType * 99 + b.position)
            );
          }
          return a.position - b.position;
        });

        // Reset position based on index and return
        return newPicks.map((p, index) => {
          const newPick = { ...p };
          newPick.position = index + 1;
          return newPick;
        });
      }

      case getType(actions.changeCaptain):
      case getType(actions.changeViceCaptain): {
        const newCaptain = state.reduce<IPickProposed | null>(
          (memo, p) => (p.element === action.payload.elementId ? p : memo),
          null
        );
        const attrMake = action.payload.isVice
          ? "is_vice_captain"
          : "is_captain";
        const attrOther = action.payload.isVice
          ? "is_captain"
          : "is_vice_captain";

        if (!newCaptain || newCaptain[attrMake]) {
          return state;
        }
        const oldCaptain = state.reduce<IPickProposed | null>(
          (memo, p) => (p[attrMake] ? p : memo),
          null
        );

        return state.map((p) => {
          const newPick = { ...p };
          if (p.element === action.payload.elementId) {
            newPick[attrMake] = true;
            newPick[attrOther] = false;
          } else {
            newPick[attrMake] = false;
            // Handle case of roles being swapped
            if (
              oldCaptain &&
              oldCaptain.element === p.element &&
              newCaptain[attrOther]
            ) {
              newPick[attrOther] = true;
            }
          }
          return newPick;
        });
      }

      default:
        return state;
    }
  },
  picksSaved: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchMyTeam.success):
      case getType(actions.saveMyTeam.success):
        return sortBy(action.payload.data.picks, "position");
      default:
        return state;
    }
  },
  savingState: (state, action: RootAction) => {
    switch (action.type) {
      case getType(actions.saveMyTeam.success):
        return "saved";
      case getType(actions.saveMyTeam.request):
        return "saving";
      default:
        return "";
    }
  },
});

// State Selectors / Helpers
export const getMyPicksLastUpdated = (state: RootState) =>
  state.myTeam.picksLastUpdated;

export const getMyPicksSaved = (state: RootState) => state.myTeam.picksSaved;

export const getMyPicksProposed = (state: RootState) =>
  state.myTeam.picksProposed;

export const getMyTeamSavingState = (state: RootState) =>
  state.myTeam.savingState;

export const getMyStartersProposed = createSelector(
  getMyPicksProposed,
  getSettings,
  (picks, settings) => picks.slice(0, settings ? settings.squad_squadplay : 0)
);

export const getMySubsProposed = createSelector(
  getMyPicksProposed,
  getSettings,
  (picks, settings) =>
    picks.slice(
      settings ? settings.squad_squadplay : picks.length,
      picks.length
    )
);

export const getMyStartingTotalsByType = createSelector(
  getMyStartersProposed,
  getElementsById,
  (picks, elements) => getTotalsByType(picks.map((p) => elements[p.element]))
);

export const getMyFormation = createSelector(
  getMyStartersProposed,
  getElementsById,
  (picks, elements) => getFormation(picks.map((p) => elements[p.element]))
);

export const isSub = (state: RootState, pick: IPickProposed) => {
  const chip = getActiveOrProposedChip(state);

  const settings = getSettings(state, undefined, chip?.id) as ISettings;
  return pick.position > settings.squad_squadplay;
};

export const isLegalSwap = (
  state: RootState,
  p1: IPickProposed,
  p2: IPickProposed,
  totals: Record<string, number>
): boolean => {
  const chip = getActiveOrProposedChip(state);

  const elementsById = getElementsById(state, undefined, chip?.id);
  const elementTypesById = getElementTypesById(state, undefined, chip?.id);
  const p1Type = elementsById[p1.element].element_type;
  const p2Type = elementsById[p2.element].element_type;
  const p1Sub = isSub(state, p1);
  const p2Sub = isSub(state, p2);
  // const locks = getPositionTypeLocks(state);
  let validSwap = false;

  // Swapping starters disallowed
  if (!p1Sub && !p2Sub) {
    return false;
  }

  // Swapping self disallowed
  if (p1.element === p2.element) {
    return false;
  }

  // Can always swap like for like
  if (p1Type === p2Type) {
    return true;
  }

  // Can swap subs unless positions locked
  if (p1Sub && p2Sub) {
    validSwap = true;
  } else if (p1Sub) {
    // Bringing p1 in, p2 out
    validSwap =
      totals[p1Type] < elementTypesById[p1Type].squad_max_play &&
      totals[p2Type] > elementTypesById[p2Type].squad_min_play;
  } else {
    // Bringing p2 in, p1 out
    validSwap =
      totals[p2Type] < elementTypesById[p2Type].squad_max_play &&
      totals[p1Type] > elementTypesById[p1Type].squad_min_play;
  }

  // Check any subs are allowed to be in their new positions
  if (
    p2Sub && // p1 becoinng a sub
    elementTypesById[p1Type].sub_positions_locked.length && // locked type
    elementTypesById[p1Type].sub_positions_locked.indexOf(p2.position) === -1
  ) {
    return false;
  }
  if (
    p1Sub && // p2 becoming a sub
    elementTypesById[p2Type].sub_positions_locked.length && // locked type
    elementTypesById[p2Type].sub_positions_locked.indexOf(p1.position) === -1
  ) {
    return false;
  }

  return validSwap;
};

export const getMyPossibleReplacementsForPick = (
  state: RootState,
  pick1: IPickProposed
) => {
  const chip = getActiveOrProposedChip(state);

  const possibles: IPickProposed[] = [];
  const totals = getMyStartingTotalsByType(state, undefined, chip?.id);
  getMyPicksProposed(state).forEach((pick2) => {
    if (isLegalSwap(state, pick1, pick2, totals)) {
      possibles.push(pick2);
    }
  });
  return possibles;
};

export const isMyTeamValid = createSelector(getMyPicksProposed, (proposed) =>
  proposed.every((p) => p.subStatus === "")
);

export const hasMyTeamChanged = createSelector(
  getMyPicksSaved,
  getMyPicksProposed,
  (saved, proposed) =>
    saved.some(
      (p, i) =>
        p.element !== proposed[i].element ||
        p.is_captain !== proposed[i].is_captain ||
        p.is_vice_captain !== proposed[i].is_vice_captain
    )
);

export const toUpdateMyTeamAPI = createSelector(getMyPicksProposed, (picks) =>
  picks.map((p) => ({
    element: p.element,
    position: p.position,
    is_captain: p.is_captain,
    is_vice_captain: p.is_vice_captain,
  }))
);

export const toUpdateChipAPI = createSelector(getMyPicksSaved, (picks) =>
  picks.map((p) => ({
    element: p.element,
    position: p.position,
    is_captain: p.is_captain,
    is_vice_captain: p.is_vice_captain,
  }))
);
