import { navigate } from "@reach/router";
import shuffle from "lodash/shuffle";
import values from "lodash/values";
import { ThunkResult } from "..";
import { apiPost, apiGet } from "../../utils/api";
import { bootstrap } from "../bootstrap/thunks";
import { cancelProposedChip } from "../chips/actions";
import {
  getProposedChip,
  getProposedChipName,
  getActiveOrProposedTransferChipName,
  getActiveOrProposedChip,
  getActiveChip,
} from "../chips/reducers";
import { getElementTypesBySquadPosition } from "../element-types/reducers";
import {
  getAverageCostByType,
  getElement,
  getElements,
} from "../elements/reducers";
import { getNextEvent } from "../events/reducers";
import { getSettings } from "../game/reducers";
import { getPlayerData } from "../player/reducers";
import {
  getCountByTeam,
  getProposedElements,
  getProposedTransfers,
  getSavedPicks,
  getToSpend,
  getTransferCostsWithoutChip,
  getTypesNeeded,
  toCreateEntryAPI,
} from "../squad/reducers";
import * as actions from "./actions";
import { resetProposed } from "./actions";
import { ICreateEntryData } from "./types";

export const proposeElement =
  (elementId: number): ThunkResult<boolean> =>
  (dispatch, getState) => {
    const chip = getActiveOrProposedChip(getState());

    const element = getElement(getState(), elementId);
    const proposed = getProposedElements(getState(), undefined, chip?.id);
    const saved = getSavedPicks(getState());
    const elementTypesByPosition = getElementTypesBySquadPosition(
      getState(),
      undefined,
      chip?.id
    );
    if (!element || !elementTypesByPosition) {
      return false;
    }

    // Element must not already be selected
    if (values(proposed).indexOf(element) > -1) {
      return false;
    }

    // Can we find a free position based on type?
    const freePosition = Object.keys(elementTypesByPosition).find((pos) => {
      return (
        elementTypesByPosition[pos].thisType.id === element.element_type &&
        !proposed[pos]
      );
    });
    if (!freePosition) {
      return false;
    }
    let position = Number(freePosition);

    // TODO - Look at bulk dispatching these with new react-redux
    const savedPositions = Object.keys(saved).filter(
      (p) => saved[p].element === elementId
    );
    // We already have this element
    if (savedPositions.length) {
      const savedPosition = Number(savedPositions[0]);
      // In a different position
      if (savedPosition !== position) {
        const proposedId =
          proposed[savedPosition] && proposed[savedPosition].id;
        // Someone is in saved position so remove them and put in this position
        if (proposedId) {
          dispatch(actions.removeElementInPosition(proposedId, savedPosition));
          dispatch(actions.proposeElementInPosition(proposedId, position));
        }
        position = savedPosition;
      }
    }
    dispatch(actions.proposeElementInPosition(element.id, position));
    return true;
  };

export const removeElement =
  (position: number): ThunkResult<boolean> =>
  (dispatch, getState) => {
    const chip = getActiveOrProposedChip(getState());

    const proposed = getProposedElements(getState(), undefined, chip?.id);
    if (!proposed[position]) {
      return false;
    }
    dispatch(actions.removeElementInPosition(proposed[position].id, position));
    return true;
  };

export const restoreElement =
  (position: number): ThunkResult<boolean> =>
  (dispatch, getState) => {
    const saved = getSavedPicks(getState());
    if (!saved[position]) {
      return false;
    }
    dispatch(
      actions.proposeElementInPosition(saved[position].element, position)
    );
    // If proposing wildcard and no transfer costs cancel it
    if (
      getProposedChipName(getState()) === "wildcard" &&
      getTransferCostsWithoutChip(getState()) === 0
    ) {
      dispatch(cancelProposedChip("wildcard"));
    }
    return true;
  };

export const reset = (): ThunkResult<void> => (dispatch, getState) => {
  const savedPicks = getSavedPicks(getState());
  dispatch(
    resetProposed(
      Object.keys(savedPicks).reduce<Record<string, number>>(
        (memo, i) => ({ ...memo, [i]: savedPicks[i].element }),
        {}
      )
    )
  );
  // Cancel any proposed transfer chips
  const chip = getProposedChip(getState());
  if (chip && chip.chip_type === "transfer") {
    dispatch(cancelProposedChip(chip.name));
  }
};

export const autoComplete =
  (): ThunkResult<boolean> => (dispatch, getState) => {
    const chip = getActiveOrProposedChip(getState());

    const typesByPosition = getElementTypesBySquadPosition(
      getState(),
      undefined,
      chip?.id
    );
    const settings = getSettings(getState(), undefined, chip?.id);
    if (!settings || !typesByPosition) {
      return false;
    }
    const averageCostByType = getAverageCostByType(getState());
    const proposed = getProposedElements(getState(), undefined, chip?.id);
    const elementsOwned = values(proposed)
      .map((e) => e.id)
      .concat(values(getSavedPicks(getState())).map((p) => p.element));
    const countByTeam = getCountByTeam(getState(), undefined, chip?.id);
    let failures = 0;

    Object.keys(typesByPosition).forEach((pos) => {
      if (!proposed[pos]) {
        const typesNeeded = getTypesNeeded(getState(), undefined, chip?.id);
        const avgToSpend = Object.keys(typesNeeded).reduce(
          (memo, et) => memo + typesNeeded[et] * averageCostByType[et],
          0
        );
        const weightingFactor =
          getToSpend(getState(), undefined, chip?.id) / avgToSpend;
        const invalidTeams = Object.keys(countByTeam)
          .filter((id) => countByTeam[id] >= settings.squad_team_limit)
          .map((id) => parseInt(id, 10));
        const possibles = getElements(getState()).filter(
          (e) =>
            // Correct type
            e.element_type === typesByPosition[pos].thisType.id &&
            // Cheap enough
            e.now_cost <=
              averageCostByType[typesByPosition[pos].thisType.id] *
                weightingFactor &&
            // Don't have
            elementsOwned.indexOf(e.id) === -1 &&
            // Available
            e.status === "a" &&
            // Within team limit
            invalidTeams.indexOf(e.team) === -1
        );
        if (possibles.length) {
          const choice = shuffle(possibles).sort(
            (a, b) =>
              parseFloat(b.form) - parseFloat(a.form) || b.now_cost - a.now_cost
          )[0];
          dispatch(
            actions.proposeElementInPosition(choice.id, parseInt(pos, 10))
          );
          elementsOwned.push(choice.id);
          countByTeam[choice.team]++;
        } else {
          failures++;
        }
      }
    });
    return !failures;
  };

export const createEntry =
  (data: ICreateEntryData): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      await apiPost(
        "entry-create/",
        { ...data, picks: toCreateEntryAPI(getState()) },
        dispatch,
        actions.createSquad
      );
    } catch (e) {
      return;
    }
    await dispatch(bootstrap());
    navigate("/my-team");
  };

export const makeTransfers =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const nextEvent = getNextEvent(getState());
    const activeChip = getActiveChip(getState());
    const player = getPlayerData(getState());
    if (!nextEvent || !player || !player.entry) {
      return;
    }
    try {
      await apiPost(
        "transfers/",
        {
          chip: getActiveOrProposedTransferChipName(getState()),
          entry: player.entry,
          event: nextEvent.id,
          transfers: getProposedTransfers(
            getState(),
            undefined,
            activeChip?.id
          ),
        },
        dispatch,
        actions.makeTransfers
      );
    } catch (e) {
      return;
    }
    navigate("/my-team");
  };

export const fetchLatestTransfers =
  (): ThunkResult<void> => async (dispatch, getState) => {
    const player = getPlayerData(getState());
    if (!player || !player.entry) {
      return;
    }
    try {
      await apiGet(
        `entry/${player.entry}/transfers-latest/`,
        dispatch,
        actions.fetchTransfersLatest
      );
    } catch (e) {
      return;
    }
  };
